import React, { useEffect, useState, useCallback } from 'react';
import PropTypes, { symbol } from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
// import { spacing } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { Event } from 'SocketIO';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { dispatch as CustomDispatch } from 'redux/actions/index';
import { widget } from 'charting_library';
import Datafeed from './Datafeed';

import Api from 'api/index';

import { deepCompare, getLanguageFromURL, countDecimals } from 'assets/js/common';
import Orders from './CorememData/Orders';
import {
  selectBalancesInfo,
  selectActiveStatus,
  selectPairData,
  selectPairItem,
  selectPairOrders,
  selectCurrentSymbol,
  selectDemoStatus,
  selectConfigPairs,
  selectStrategiesConfig,
  selectConfigExchanges,
} from 'redux/selectors';
import OverviewPairCard from 'components/Gunbot/Dashboard/OverviewPairCard';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import TradingTerminal from 'views/TradingTerminal';
import Alerts from 'components/TVChartContainer/Alerts';
import AlertProcessor from 'components/TVChartContainer/AlertProcessor';
import MarketSidebarNavigation from 'components/TVChartContainer/Components/MarketSidebarNavigation';
import RightSidebarNavigation from 'components/TVChartContainer/Components/RightSidebarNavigation';
import healMissingStrategySettings from 'components/Gunbot/HealMissingStrategySettings';
import healMissingExchangeSettings from 'components/Gunbot/HealMissingExchangeSettings';
import healStrategyCasing from 'components/Gunbot/HealStrategyCasing';
import CoreDisabled from 'views/CoreDisabled';
import getLicenseType from 'components/Gunbot/LicenseLevel.js';
import Notes from 'components/Gunbot/Notes.js';
import $scope from 'consts/scope';

import { selectIsOpenMarketsSideBar, selectSideBarState, selectIsOpenSideBar } from 'redux/selectors/dashboard';
import { ActionToggleAlerts } from 'redux/actions/dashboard';

import { useSnackbar } from 'notistack';

import tvNavigationColors from './Components/tvNavigationColors';
import MarketsTable from 'components/TVChartContainer/Components/MarketsTable';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ChartIcon from '@material-ui/icons/BarChart';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import TradingIcon from '@material-ui/icons/Payment';

const canSafelyInteractWithChart = function () {
  try {
    if (
      window?.tvWidget &&
      typeof window.tvWidget._innerAPI === 'function' &&
      window.tvWidget._innerAPI() && // Ensures _innerAPI does not return null
      typeof window.tvWidget.activeChart === 'function' &&
      window.tvWidget.activeChart() // Ensures activeChart does not return null
    ) {
      return true;
    }
  } catch (error) {
    console.warn("Error while checking chart safety:", error);
  }
  return false;
}


const useStyle = makeStyles(() => ({
  rootMobile: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  rootDesktop: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  chartWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1, // lower than the sidebar
  },
  bottomNavigation: {
    position: 'absolute', // adjust as needed
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1001,
  },
  loadingMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#222222',
    color: '#9e9e9e',
    zIndex: 10,
  },
  header: {
    height: '38px',
    borderBottom: '1px solid rgba(42, 46, 57, 0.8)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    gap: '12px',
  },
  headerControls: {
    display: 'flex',
    gap: '8px',
    '& > div': {
      width: '80px',
      height: '22px',
      backgroundColor: 'rgba(158, 158, 158, 0.08)',
      borderRadius: '4px',
    },
  },
  sidebar: {
    position: 'absolute',
    top: '38px',
    left: 0,
    width: '48px',
    height: 'calc(100% - 38px)',
    borderRight: '1px solid rgba(42, 46, 57, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '12px 8px',
  },
  sidebarIcon: {
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(158, 158, 158, 0.08)',
    borderRadius: '4px',
  },
  chartArea: {
    position: 'absolute',
    top: '38px',
    left: '48px',
    right: 0,
    bottom: 0,
  },
  chartSkeleton: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    right: '20px',
    bottom: '20px',
    opacity: 0.08,
    '& .candle': {
      position: 'absolute',
      width: '4px', // Slimmer candles
      backgroundColor: '#9e9e9e',
      transition: 'all 0.3s ease',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        width: '1px',
        backgroundColor: 'inherit',
        transform: 'translateX(-50%)',
      },
    },
  },
  chartBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.03,
    backgroundImage: `
      linear-gradient(0deg, transparent 24%,
      rgba(158, 158, 158, 0.2) 25%,
      rgba(158, 158, 158, 0.2) 26%,
      transparent 27%, transparent 74%,
      rgba(158, 158, 158, 0.2) 75%,
      rgba(158, 158, 158, 0.2) 76%,
      transparent 77%, transparent),
      linear-gradient(90deg, transparent 24%,
      rgba(158, 158, 158, 0.2) 25%,
      rgba(158, 158, 158, 0.2) 26%,
      transparent 27%, transparent 74%,
      rgba(158, 158, 158, 0.2) 75%,
      rgba(158, 158, 158, 0.2) 76%,
      transparent 77%, transparent)
    `,
    backgroundSize: '50px 50px',
  },
  loadingIndicator: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: 'rgba(34, 34, 34, 0.95)',
    padding: '16px 24px',
    borderRadius: '4px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
  dots: {
    display: 'flex',
    gap: '4px',
    '& span': {
      width: '4px',
      height: '4px',
      backgroundColor: '#787B86',
      borderRadius: '50%',
      animation: '$bounce 1.4s infinite ease-in-out both',
      '&:nth-of-type(1)': {
        animationDelay: '-0.32s',
      },
      '&:nth-of-type(2)': {
        animationDelay: '-0.16s',
      },
      '&:nth-of-type(3)': {
        animationDelay: '0s',
      },
    },
  },
  text: {
    fontSize: '15px',
    fontWeight: 400,
    letterSpacing: '0.1px',
    opacity: 0.8,
  },
  '@keyframes bounce': {
    '0%, 80%, 100%': {
      transform: 'scale(0.6)',
      opacity: 0.6,
    },
    '40%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
  TVChartContainer: {
    height: '100%',
    width: '100%',
    //flex: 'auto',
    '--tv-color-platform-background': '#222',
    '--tv-color-pane-background': '#222 !important',
  },
  overviewSideBarWrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    backgroundColor: '#222222',
    '&.left': {
      borderLeft: 'none',
    },
  },
  overviewSideBar: {
    position: 'relative',
    height: '100%',
    width: '400px',
    overflow: 'hidden',
    borderLeft: `0.5px solid ${tvNavigationColors.line}`,
    // transition: "width .5s ease-out",
    '&.hidden': {
      width: 0,
    },
    '& .overview-pair-card': {
      height: '100%',
      overflowY: 'auto',
      '& > div': {
        padding: 0,
      },
      '& div[class*="SP-param "] .MuiInputBase-root': {
        height: 60,
      },
    },
  },
  leftSide: {
    width: 250,
    borderLeft: 'none',
    borderRight: `0.5px solid ${tvNavigationColors.line}`,
    '&.hidden': {
      width: 0,
    },
  },
  overflow: {
    overflow: 'hidden',
    overflowY: 'auto',
  },
   mobileLoadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#222222',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  mobileLoadingIndicator: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: 'rgba(34, 34, 34, 0.95)',
    padding: '16px 24px',
    borderRadius: '4px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
  mobileLoadingDots: {
    display: 'flex',
    gap: '4px',
    '& span': {
      width: '4px',
      height: '4px',
      backgroundColor: '#787B86',
      borderRadius: '50%',
      animation: '$mobileBounce 1.4s infinite ease-in-out both',
    },
  },
  mobileLoadingText: {
    fontSize: '15px',
    fontWeight: 400,
    letterSpacing: '0.1px',
    opacity: 0.8,
  },
  '@keyframes mobileBounce': {
    '0%, 80%, 100%': {
      transform: 'scale(0.6)',
      opacity: 0.6,
    },
    '40%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

const shortLabels = {
  'Moving Average Exponential': 'EMA',
  'Bollinger Bands': 'BB',
  'Relative Strength Index': 'RSI',
  'Parabolic SAR': 'SAR',
  'Ichimoku Cloud': 'Ichimoku',
};

let noneStates = {
  exportConditionTimer: null,
  tIdSetSymbol: null,
};

const getConfigStatus = async function () {
  return Api.checkConfigStatus();
};

window.startStopPressed = false

let lastSymbolSet = null

export default function TVChartContainer(props) {
  const dispatch = useDispatch();
  const stream = props.stream;
  const history = props.history
  const urlExchange = new URLSearchParams(props.history.location.search).get("exchange")
  const urlPair = new URLSearchParams(props.history.location.search).get("pair")
  const [hasStarted, setHasStarted] = useState(false);


  if (!_.isNil(urlExchange)) {
    localStorage.setItem('appExchange', urlExchange)
  }
  if (!_.isNil(urlPair)) {
    localStorage.setItem('appPair', urlPair)
  }
  const settings = useSelector(state => state.settings);
  const licenseType = getLicenseType(settings.config);

  const pairs = useSelector(selectConfigPairs);
  const strategies = useSelector(selectStrategiesConfig);
  const exchanges = useSelector(selectConfigExchanges);
  const isDemo = useSelector(selectDemoStatus);
  const wallet = useSelector(state => state.settings.config.bot?.gunthy_wallet, _.isEqual);

  const config = useSelector(state => state.settings.config, _.isEqual);

  const currentSymbol = useSelector(selectCurrentSymbol);
  const balances = useSelector(selectBalancesInfo);
  const symbols = useSelector(state => state.settings.corememData?.symbols, _.isEqual) || [];
  const active = useSelector(selectActiveStatus);
  const okexMarket = _.isNil(exchanges.okex5)
    ? 'spot'
    : !_.isNil(exchanges.okex5.market)
      ? exchanges.okex5.market
      : 'spot';
  const okgbMarket = _.isNil(exchanges.okgunbot)
    ? 'spot'
    : !_.isNil(exchanges.okgunbot.market)
      ? exchanges.okgunbot.market
      : 'spot';
  const bybitMarket = _.isNil(exchanges.bybit)
    ? 'spot'
    : !_.isNil(exchanges.bybit.market)
      ? exchanges.bybit.market
      : 'spot';
  const { exchange, pair } = currentSymbol;
  const key = `${exchange}/${pair}`;

  window.selectedBalanceInfo = null
  window.selectedBalanceInfo = (balances || []).find(
    balanceItem => balanceItem.exchange === exchange && balanceItem.pair === pair,
  );

  const pairData = useSelector(selectPairData(key) || {});

  const orders = useSelector(selectPairOrders(key));

  const whatstrat = useSelector(selectPairItem(key, 'whatstrat'));

  /** SideBar state **/
  const isOpenMarketsSideBar = useSelector(selectIsOpenMarketsSideBar);
  const sideBarState = useSelector(selectSideBarState);
  const isOpenSideBar = useSelector(selectIsOpenSideBar);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [datafeed, setDatafeed] = useState(null);

  const [isHeaderCreated, setHeaderCreated] = useState(false);
  const [baseBalanceButton, setBaseBalanceButton] = useState(null);
  const [quoteBalanceButton, setQuoteBalanceButton] = useState(null);
  const [position, setPosition] = useState('LONG');

  const [lastOrders, setLastOrders] = useState({});
  const [redrawOrders, setRedrawOrders] = useState(false);
  const [pairStrat, setPairStrat] = useState({});
  const [entityIds, setEntityIds] = useState([]);
  const [conditions, setConditions] = useState({});
  const [indicatorsToKeep, setIndicatorsToKeep] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const embeddedView = !_.isNil(urlExchange) && !_.isNil(urlPair)
  const [navbarValue, setNavbarValue] = useState(0);
  const [lineCount, setLineCount] = useState(0);
  const [startExport, setStartExport] = useState(false);
  const [hasSetInitSymbol, setHasSetInitialSymbol] = useState(false);
  const [hasSetInitIndicators, setHasSetInitIndicators] = useState(false);
  const [templateIndicators, setTemplateIndicators] = useState([])
  const [usedUrlParams, setUsedUrlParams] = useState()


  // Nullify previous values to prevent memory leaks
  window.symbols = null;
  window.isOpenMarketsSideBar = null;
  window.sideBarState = null;
  window.isOpenSideBar = null;
  window.currentSymbol = null;
  window.whatstrat = null;
  window.nextTL = null;
  window.shouldUseMarks = null;

  // Assign new values
  window.symbols = symbols;
  window.isOpenMarketsSideBar = isOpenMarketsSideBar;
  window.sideBarState = sideBarState;
  window.isOpenSideBar = isOpenSideBar;
  window.currentSymbol = currentSymbol;
  window.whatstrat = whatstrat;
  window.nextTL = pairData?.nextTL || 0;

  window.shouldUseMarks = _.isNil(config?.bot?.CHART_MARKS) ? true : config?.bot?.CHART_MARKS
  const isMobile = window.innerWidth <= 600;
  const isHorizontal = useMediaQuery('(max-height: 500px)');

  const sendNotification = function (text, variant, persist, hideMarket, showLonger) {
    if (embeddedView || config.bot?.SHOW_ORDER_NOTIFICATIONS === false) {
      return
    }
    let messageText = [text];
    let firstLine = '';
    if (text.split(/\r?\n/).length > 1) {
      messageText = _.isNil(text) ? ['', ''] : text.split(/\r?\n/).filter(i => i);
      firstLine = messageText.shift();
    }
    const showPair = hideMarket === true ? false : true;
    const semiPersist = _.isNil(showLonger) ? false : showLonger;


    const contentWithButton = key => (
      <>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{
            marginLeft: '-18px',
            marginRight: '10px',
            padding: '0',
            minWidth: isMobile ? '67vw' : '225px',
            maxWidth: isMobile ? 'auto' : '350px',
            justifyContent: 'space-between',
            marginTop: isMobile ? '-2px' : 'auto',
          }}
        >
          <Grid item>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: 'white', padding: '0' }}>
              {firstLine}
            </Typography>
          </Grid>
          {!_.isNil(messageText) &&
            messageText.length > 0 &&
            messageText.map(element => {
              return (
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="paragraph" style={{ padding: '0' }}>
                    {element}
                  </Typography>
                </Grid>
              );
            })}
          {showPair && (
            <Grid item style={{ paddingTop: '8px' }}>
              <Typography variant="paragraph" style={{ fontSize: 'smaller' }}>
                {pair.split('-')[0]}-{pair.split('-')[1]}{' '}
                {$scope.exchangesWithUsableTickers?.[exchange]?.prettyName || exchange}
              </Typography>
            </Grid>
          )}
          <Grid item style={showPair ? {} : { paddingTop: '8px' }}>
            <Typography variant="paragraph" style={{ paddingTop: '12px', fontSize: 'smaller' }}>
              {new Date().toLocaleTimeString()} {new Date().toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>

        <Button
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dismiss
        </Button>
      </>
    );

    const contentNoButton = key => (
      <>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{
            marginLeft: '-18px',
            marginRight: '10px',
            padding: '0',
            minWidth: isMobile ? '67vw' : '265px',
            maxWidth: isMobile ? 'auto' : '350px',
            justifyContent: 'space-between',
            marginTop: isMobile ? '-2px' : 'auto',
          }}
        >
          <Grid item>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: 'white' }}>
              {firstLine}
            </Typography>
          </Grid>
          {!_.isNil(messageText) &&
            messageText.length > 0 &&
            messageText.map(element => {
              return (
                <Grid item>
                  <Typography variant="paragraph">{element}</Typography>
                </Grid>
              );
            })}
          {showPair && (
            <Grid item style={{ paddingTop: '8px' }}>
              <Typography variant="paragraph" style={{ fontSize: 'smaller' }}>
                {pair.split('-')[0]}-{pair.split('-')[1]}{' '}
                {$scope.exchangesWithUsableTickers?.[exchange]?.prettyName || exchange}
              </Typography>
            </Grid>
          )}
          <Grid item style={showPair ? {} : { paddingTop: '8px' }}>
            <Typography variant="paragraph" style={{ paddingTop: '12px', fontSize: 'smaller' }}>
              {new Date().toLocaleTimeString()} {new Date().toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
      </>
    );

    const action = persist ? contentWithButton : contentNoButton;
    enqueueSnackbar('', {
      variant: variant || 'success',
      preventDuplicate: true,
      autoHideDuration: semiPersist ? 20000 : 5000,
      style: { whiteSpace: 'pre-line' },
      persist: (persist && !semiPersist) || false,
      //key: action,
      action,
    });
  };

  const gotoNewTab = function (path) {
    window.open(path, '_blank');
  };

  const handleShowSRLines = function () {
    const SRstatus = localStorage.getItem('showSR') === 'false' ? false : true;
    const message = !SRstatus
      ? 'Enabled support / resistance lines \n\nChange takes effect from next processing round'
      : 'Disabled support / resistance lines \n\nChange takes effect from next processing round';

    if (SRstatus === true) {
      localStorage.setItem('showSR', false);
    } else {
      localStorage.setItem('showSR', true);
    }

    sendNotification(message, 'info', false, true, false);
  };

  // custom notifications with help and dismiss button
  const sendCustomNotification = function (text) {
    if (embeddedView) {
      return
    }
    const action = key => (
      <>
        <Button
          onClick={() => {
            gotoNewTab('https://wiki.gunthy.org/troubleshooting/restore-backup');
          }}
        >
          Help
        </Button>
        <Button
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dismiss
        </Button>
      </>
    );

    let messageContent = text;

    enqueueSnackbar(messageContent, {
      variant: 'error',
      preventDuplicate: true,
      persist: true,
      style: { whiteSpace: 'pre-line' },
      action,
    });
  };

  // send notification when a corrupt config has been detected
  useEffect(() => {
    async function getStatus() {
      const apiResponse = await getConfigStatus();
      if (apiResponse.usingFallbackConfig) {
        sendCustomNotification(
          'Corrupt config.js file detected, please restore a backup. \nA safe fallback config has been loaded.',
        );
      }
    }
    getStatus();
  }, [pairs]);

  // check license data and save to local storage, used to hide license level specific features
  useEffect(() => {
    async function checkGunthyWallet() {
      const result = await Api.checkGunthyWallet(wallet);
      if (result?.status === 'success') {
        localStorage.setItem('licenseDetails', JSON.stringify(result.details));
        localStorage.setItem('licenseDetailsLastUpdate', Math.round(+new Date() / 1000));
      }
    }
    if (
      (!_.isNil(wallet) &&
        wallet.toLowerCase().startsWith('0x') &&
        (_.isNil(localStorage.getItem('licenseDetails')) ||
          localStorage.getItem('licenseDetailsLastUpdate') <= Math.round(+new Date() / 1000) - 3600)) ||
      wallet != JSON.parse(localStorage.getItem('licenseDetails'))?.walletAddress
    ) {
      checkGunthyWallet();
    }
  }, []);

  // load sidebars visibility from localstorage and set accordingly
  useEffect(() => {
    // check there are any strats with missing parameters, heal them
    if (isDemo) {
      // do nothing
    } else {
      const healedStrats = healMissingStrategySettings(strategies);

      if (healedStrats === false) {
        // do nothing
      } else {
        dispatch(CustomDispatch('putConfig', healedStrats, 'strategies'));
        sendNotification(
          'Config healed\n\nStrategies with missing parameters detected and fixed. Please save changes.',
          'info',
          true,
          true,
        );
      }

      const healedExchanges = healMissingExchangeSettings(exchanges);
      if (healedExchanges === false) {
        // do nothing
      } else {
        dispatch(CustomDispatch('putConfig', healedExchanges, 'exchanges'));
        sendNotification(
          'Config healed\n\nExchanges with missing exchange parameters fixed. Please save changes and review your trading fees settings.',
          'info',
          true,
          true,
        );
      }
      const healedStrategyCasingPairSection = healStrategyCasing(pairs, 'pairs');
      const healedStrategyCasing = healStrategyCasing(strategies, 'strategies');
      if (healedStrategyCasingPairSection === false) {
        // do nothing
      } else {
        dispatch(CustomDispatch('putConfig', healedStrategyCasingPairSection, 'pairs'));
        sendNotification(
          'Config healed\n\nForce changed all assigned strategy names to lower case. Please save changes.',
          'info',
          true,
          true,
        );
      }
      if (healedStrategyCasing === false) {
        // do nothing
      } else {
        dispatch(CustomDispatch('putConfig', healedStrategyCasing, 'strategies'));
        sendNotification(
          'Config healed\n\nForce changed all strategy names to lower case. Please save changes.',
          'info',
          true,
          true,
        );
      }

      const webhooksConfig = {
        enabled: false,
        wh_port: '443',
        wh_passphrase: '123456',
      };

      let needHealWebhooks = false;
      if (Object.keys(settings.config).indexOf('webhooks') < 0) {
        needHealWebhooks = true;
      } else if (Object.keys(settings.config.webhooks).length < 3) {
        needHealWebhooks = true;
      }

      if (needHealWebhooks) {
        dispatch(CustomDispatch('putConfig', webhooksConfig, 'webhooks'));

        sendNotification(
          'Config healed\n\nAdded missing config parameters for webhooks. Please save changes.',
          'info',
          true,
          true,
        );
      }
    }
  }, []);

  const setCurrentSymbol = (symbol, pair) => {
    if ((symbol.pair !== currentSymbol.pair && pair == symbol.pair) || symbol.exchange !== currentSymbol.exchange) {
      dispatch(CustomDispatch('setCurrentSymbol', symbol))
    }
  };

  const getPeriod = (symbolInfo, numerical) => {
    const pair = symbolInfo.pair
    const exchange = symbolInfo.exchange
    let pairConfig = dummyData.pairs.binance['BTC-DUMMY']
    let pairStrategyConfig = dummyData.strategies.dummy
    let pairPeriod = 1440

    const interval = {
      1: '1',
      3: '3',
      5: '5',
      15: '15',
      30: '30',
      60: '1h',
      120: '2h',
      240: '4h',
      360: '6h',
      480: '8h',
      720: '12h',
      1440: '24h',
      4320: '3d',
      10080: '7d',
      20160: '14d',
    }

    if (!_.isNil(config)) {
      if (!_.isNil(config.pairs)) {
        if (!_.isNil(config.pairs[exchange])) {
          if (!_.isNil(config.pairs[exchange][pair])) {
            pairConfig = config.pairs[exchange][pair]
            pairStrategyConfig = config.strategies[pairConfig.strategy]
            if (!_.isNil(pairConfig.override.PERIOD)) {
              pairPeriod = parseFloat(pairConfig.override.PERIOD)
            }
            else {
              pairPeriod = parseFloat(pairStrategyConfig.PERIOD)
            }
          }
        }
      }
    }

    if (pairPeriod === 1) {
      if (!_.isNil(pairs[exchange])) {
        if (!_.isNil(pairs[exchange][pair])) {
          pairConfig = pairs[exchange][pair]
          pairStrategyConfig = strategies[pairConfig.strategy]
          if (!_.isNil(pairConfig.override.PERIOD)) {
            pairPeriod = parseFloat(pairConfig.override.PERIOD)
          }
          else {
            pairPeriod = parseFloat(pairStrategyConfig.PERIOD)
          }
        }
      }
    }

    if (numerical) {
      return pairPeriod
    }
    else {
      return interval[pairPeriod] || pairPeriod
    }

  };

  if (_.isNil(window.getPeriod)) {
    window.getPeriod = getPeriod
  }


  const onCancelOrder = (exch, pair, id, type, price) => {
    const data = { price, id, type, pair, exch };
    return Api.cancelOrder(data)
      .then(() => {
        try {
          // try removing order line, if it fails it gets automatically cleaned out next time the gui receives changed open orders data
          let orderLineToRemove = _.find(window.primitives, item => item?._data?.bodyText?.includes(id));
          orderLineToRemove.remove()
        } catch (error) {
          // do nothing
          //console.log('silent catch25', error)
        }
        // send notification if change in open orders
        const { openOrders = [] } = pairData;
        const newOpenOrders = openOrders.filter(item => item.pair !== pair && item.id !== id);
        if (!deepCompare(openOrders, newOpenOrders)) {
          setTimeout(() => {
            sendNotification('Order canceled', 'info', false, false, false);
          }, 100);
        }
      })
      .catch(error =>
        console.log('Error<onCancelOrder> :', error)
      );
  };

  const updateCb = useCallback(function () {
    stream['updateCb'](...arguments);
  }, []);

  const setSymbol = symbolInfo => {
    const { exchange, pair } = symbolInfo;
    const symbolName = `${pair} / ${exchange.toUpperCase()}`;
    if (selectedSymbol !== symbolInfo.name) {
      setSelectedSymbol(symbolInfo.name)
    }
    if (!_.isNil(window.tvWidget)) {
      window.tvWidget
        .setSymbol(symbolName, getPeriod(symbolInfo), () => {
          setCurrentSymbol(symbolInfo, pair)
        });
    }

    setTimeout(() => {
      setRedrawOrders(Math.random())
    }, 5000);
  };

  if (_.isNil(window.setSymbol)) {
    window.setSymbol = setSymbol;
  }


  /*
  const onSelect = useCallback(e => {
    let element = e.target;
    element.value = '';

    let evt = document.createEvent('HTMLEvents');
    evt.initEvent('change', false, true);
    element.dispatchEvent(evt);

    element.value = ' ';
    element.dispatchEvent(evt);
  }, []);
  */

  /*
  const exportData = (window.exportData = () => {
    if (active) {
      window.tvWidget
        .activeChart()
        .exportData({
          includeTime: true,
          includeSeries: false,
          includedStudies: 'all',
        })
        .catch(error => {
          console.log(error);
        })
        .then(({ schema, data }) => {
          if (!_.isNil(schema) && !_.isNil(data)) {
            const renderedData = schema;
            data.forEach((item, dataIndex) => {
              item.forEach((value, schemaIndex) => {
                if (!renderedData[schemaIndex].data) {
                  renderedData[schemaIndex].data = [];
                }
                renderedData[schemaIndex].data[dataIndex] = value;
              });
            });
            Api.sendExportedIndicatorsData(exchange, pair, renderedData);
          }
        });
    }
  });
  */
  // const getStudyByName = (studyName) => {
  // 	let info = tvWidget.activeChart().getAllStudies().find(({ name }) => {
  // 		return name === studyName;
  // 	});
  // 	if (info) {
  // 		let study = window.tvWidget.activeChart().getStudyById(info.id);
  // 		//console.log(study.getInputsInfo());
  // 		//console.log(study.getInputValues());
  // 		return study;
  // 	}
  // 	return null;
  // };


  window.exportConditions = null

  const exportConditions = () => {
    const newConditions = {};
    function renderSourceTitle(title = '') {
      for (const searchMatch in shortLabels) {
        if (title.indexOf(searchMatch) >= 0) {
          return title.replace(searchMatch, shortLabels[searchMatch]);
        }
      }
      return title;
    }
    if (!_.isNil(window.tvWidget) && active && selectedSymbol) {
      window.tvWidget
        .activeChart()
        .exportData({
          from: Date.now() / 1000,
          includeSeries: false,
          includeTime: false,
          includedStudies: 'all',
        })
        .then(({ schema, data }) => {
          if (!_.isNil(schema) && !_.isNil(data)) {
            const [latest] = data;
            if (latest) {
              schema.forEach((info, index) => {
                let sourceTitle = renderSourceTitle(info.sourceTitle);
                if (sourceTitle.startsWith('Volume')) return;
                if (!newConditions[sourceTitle]) {
                  newConditions[sourceTitle] = { value: [latest[index]] };
                } else {
                  newConditions[sourceTitle].value.push(latest[index]);
                }
              });
            }
            let currentSymbolInfo = window.symbols.find(
              symbol => symbol.exchange === window.currentSymbol.exchange && symbol.pair === window.currentSymbol.pair,
            );
            let decimalSize = countDecimals(
              (currentSymbolInfo && currentSymbolInfo.filters && currentSymbolInfo.filters[0].tickSize) || 0.00000001,
            );

            let shapes = window.tvWidget
              .activeChart()
              .getAllShapes()
              .filter(({ name }) => name !== 'icon');

            shapes.forEach(({ name, id }) => {
              let shape = null
              if (window.tvWidget && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart()) {
                window.tvWidget.activeChart().getShapeById(id);
              }
              let points = shape.getPoints();
              if (name === 'trend_line') {
                newConditions[
                  `Trend Line ${points[0].price.toFixed(decimalSize)}~${points[1].price.toFixed(decimalSize)}`
                ] = { info: points };
              } else if (name === 'horizontal_line') {
                newConditions[`Horizontal Line ${points[0].price.toFixed(decimalSize)}`] = { value: [points[0].price] };
              } else if (name === 'parallel_channel') {
                newConditions[
                  `Parallel Channel ${points[0].price.toFixed(decimalSize)}~${points[1].price.toFixed(decimalSize)}`
                ] = { info: points };
              } else if (name === 'fib_retracement') {
                let properties = shape.getProperties();
                let levels = Object.keys(properties)
                  .filter(key => /level\d+/g.test(key) && properties[key].visible)
                  .map(key => properties[key]);
                newConditions[
                  `Fibonacci Retracements ${points[0].price.toFixed(decimalSize)}~${points[1].price.toFixed(
                    decimalSize,
                  )}`
                ] = { info: points, levels };
              }
            });

            // set the exported items to be used as entities in alerts
            if (!_.isNil(newConditions)) {
              setConditions(prev => {
                return deepCompare(prev, newConditions) ? prev : newConditions;
              });
            }
          }
        })
        .catch(error => {
          //console.log('silent catch27', error)
        });
    }

    // repeat in 60 secs (turned down from 1, it was making the chart behave laggy)
    noneStates.exportConditionTimer = setTimeout(() => {
      if (active) {
        window.exportConditions();
        noneStates.exportConditionTimer = null;
      }
    }, 60000);


  }

  const loading = 'Loading';

  useEffect(() => {
    if (!symbols || symbols.length === 0 || hasStarted === true) {
      // Symbols are not ready yet; do not initialize the chart
      return;
    }

    if (window.tvWidget) {
      // Chart is already initialized; prevent re-initialization
      return;
    }

    const symbolInfo = !_.isNil(symbols) && symbols.length > 0 ? symbols[0] : { pair: loading, symbol: loading };
    setCurrentSymbol(symbolInfo);
    const onChangeHandler = function (symbolInfo) {
      // Not in use, handled by subscription to chart symbol change events
      /*
      setCurrentSymbol(symbolInfo, symbolInfo.pair);
      setSelectedSymbol(symbolInfo.name)
      setSymbol(symbolInfo);
      */
    };
    setHasStarted(true);
    const datafeed = new Datafeed({
      debug: false,
      symbols: symbols,
      onSymbolChanged: onChangeHandler,
      stream,
    });
    setDatafeed(datafeed);

    const widgetOptions = {
      datafeed,
      symbol: symbolInfo.symbol,
      debug: false,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      style: '2',
      theme: 'Dark',
      toolbar_bg: '#222222',
      interval: getPeriod(symbolInfo),
      container: isMobile
      ? `${props.containerId}_mobile`
      : `${props.containerId}_desktop`,
      library_path: props.libraryPath,
      locale: getLanguageFromURL() || 'en',
      load_last_chart: true,
      enabled_features: [
        'datasource_copypaste',
        'use_localstorage_for_settings',
        'snapshot_trading_drawings',
        'supports_timescale_marks',
        'hide_last_na_study_output',
        'iframe_loading_compatibility_mode',
        'use_na_string_for_not_available_values',
        'determine_first_data_request_size_using_visible_range',
        'request_only_visible_range_on_reset',
        'create_volume_indicator_by_default',
        'create_volume_indicator_by_default_once',
        'volume_force_overlay',
        /*'disable_resolution_rebuild'*/
      ],
      disabled_features: [
        "study_templates",
        "clear_price_scale_on_error_or_empty_bars",
        /*"header_resolutions"*/
      ],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      studies_overrides: props.studiesOverrides,
      custom_css_url: "/customtv.css",
      overrides: {
        volumePaneSize: 'medium',
        editorFontsList: ['Verdana', 'Courier New', 'Times New Roman', 'Arial'],
        "paneProperties.background": "#222222",
        "paneProperties.backgroundType": "solid",
        'mainSeriesProperties.showCountdown': true,
        "clear_price_scale_on_error_or_empty_bars": false,
      },
      loading_screen: { backgroundColor: '#222222' },

      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([

          {
            name: 'Elliot Waves Oscillator',
            metainfo: {
              _metainfoVersion: 40,
              id: 'Elliot Waves Oscillator@tv-basicstudies-1',
              scriptIdPart: '',
              name: 'Elliot Waves Oscillator',
              description: 'Elliot Waves Oscillator',
              shortDescription: 'EWO',
              is_hidden_study: false,
              is_price_study: false,
              isCustomIndicator: false,
              plots: [{ id: 'EMA', type: 'line' }],
              defaults: {
                styles: {
                  EMA: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 40,
                    color: '#C2DDF8',
                  },
                },
                precision: 4,
                inputs: {
                  emaLength: 5,
                  options: 'Elliot Waves Oscillator',
                },
              },
              styles: {
                EMA: {
                  title: 'EWO Value',
                  histogramBase: 0,
                },
              },
              inputs: [
                {
                  id: 'emaLength',
                  type: 'integer',
                  name: 'Waves Length',
                  defval: 5,
                  min: 1,
                  max: 1e3,
                },
                {
                  id: 'options',
                  type: 'text',
                  name: 'Options',
                  defval: 'Elliot Waves Oscillator',
                  options: ['Elliot Waves Oscillator'],
                },
              ],
            },

            constructor: function () {
              this.init = function (context, inputCallback) { };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                var v = this._context.new_var(PineJS.Std.close(this._context));
                var indiEma = PineJS.Std.ema(v, 10, context) - PineJS.Std.ema(v, 70, context);

                return [indiEma];
              };
            },
          },
          {
            // Replace the <study name> with your study name
            // The name will be used internally by the Charting Library
            name: 'linreg_twist',
            metainfo: {
              _metainfoVersion: 40,
              id: 'linreg_twist@tv-basicstudies-1',
              scriptIdPart: 'linreg_twist@tv-basicstudies-1',
              name: 'linreg_twist',

              // This description will be displayed in the Indicators window
              // It is also used as a "name" argument when calling the createStudy method

              description: 'linreg_twist',
              shortDescription: 'linreg_twist',
              is_price_study: true,
              isCustomIndicator: true,

              //    "plots": [{"id": "plot_0", "type": "line"}],
              defaults: {
                styles: {
                  plot_0: {
                    //linestyle: 0,
                    visible: true,
                    //linewidth: 3,
                    plottype: 1,
                    //trackPrice: false,
                    transparency: 40,
                    // Plot color in #RRGGBB format
                    color: '#0000FF',
                  },
                },
                precision: 2,
                inputs: {},
              },

              plots: [
                {
                  id: 'plot_0',
                  type: 'hystogram',
                },
              ],

              styles: {
                plot_0: {
                  title: 'linreg_twist',
                  histogramBase: 0,
                  joinPoints: !1,
                },
              },
              inputs: [],
            },

            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                //Basically just using a 4 period sma to filter input for a linreg function w length 100 and offset 0
                //linreg should be calculated on a H4 period.
                //I have no idea if this is even syntax correct, not much of a coder as you might notice..
                const symbol = symbolInfo.symbol;
                this._context.new_sym(symbol, PineJS.Std.period(this._context), PineJS.Std.period(this._context));
              };

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;
                this._context.select_sym(1);

                const offset = 0;
                const len = 100;
                const input0 = this._input(0);
                const source = PineJS.Std.close(this._context);
                //const smooth = PineJS.Std.sma(source, input0, this._context);
                //const lr = PineJS.Std.linreg(smooth, len, offset, this._context);
                var v = PineJS.Std.sma(source, 5, this._context);
                var l = PineJS.Std.sma(source, 35, this._context);
                return [v - l];
                //return [lr];
              };
            },
          },

        ]);
      },
    };

    const tvWidget = new widget(widgetOptions);

    const debounceOrderData = _.debounce(updateOrderArrows, 100);

    function updateOrderArrows() {
      // use random data as it only needs to change
      setRedrawOrders(Math.random());
    }



    if (_.isNil(window.tvWidget)) {
      window.tvWidget = tvWidget;
    }

    tvWidget.onChartReady(() => {
      // get orders and marks first time the chart loads

      setTimeout(() => {
        debounceOrderData()
        if (window.tvWidget && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart()) {
          window.tvWidget && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart().resetData()
          tvWidget.activeChart().refreshMarks()
        }
      }, 5000);

      // subscribe to changes in visible range, to make sure orders get drawn for visible timeframe
      tvWidget
        .activeChart()
        .onVisibleRangeChanged()
        .subscribe(null, ({ from, to }) => debounceOrderData())

      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, (symbolData) => {
          // clean all primitives when changing symbols, so that new ones get created and there can be no mixups between lines that might be for an inactive subscription
          const windowSymbolName = `${window?.currentSymbol?.pair} / ${window?.currentSymbol?.exchange.toUpperCase()}`;
          if (windowSymbolName !== symbolData.name) {
            if (!_.isNil(window.primitives)) {
              window.primitives.forEach(function (primitive, index) {
                if (typeof primitive === 'object' && !_.isNil(primitive)) {
                  const id = primitive?._line?._id;
                  if (typeof id != 'undefined') {
                    try {
                      // remove primitive from chart
                      primitive.remove();
                    } catch (error) {
                      //console.log('silent catch1', error)
                    }
                  }

                }
              });
              window.primitives = null
              window.primitives = []
              if (!_.isNil(window[window.currentSymbol.exchange])) {
                if (!_.isNil(window[window.currentSymbol.exchange][window.currentSymbol.pair])) {
                  if (!_.isNil(window[window.currentSymbol.exchange][window.currentSymbol.pair].primitives)) {
                    delete window[window.currentSymbol.exchange][window.currentSymbol.pair].primitives
                  }
                }
              }
            }
          }
          // send subscribe event for new pair, this forces the stream to get bars from new pair
          if (!_.isNil(symbolData) && !_.isNil(stream) && windowSymbolName !== symbolData.name) {
            const UID = `${symbolData.exchange}:${symbolData.pair}`
            stream.sendSubRequestEvent({ sub: UID, from: 0 });
          }

          setSelectedSymbol(symbolData.name)
          setCurrentSymbol(symbolData, symbolData.pair);

          // ensure order arrows and marks get refreshed after symbol has changed
          setTimeout(() => {
            debounceOrderData()
            tvWidget.activeChart().refreshMarks()
          }, 1000);
        })


      const currentLayoutName = tvWidget.layoutName()

      if (!currentLayoutName) {
        // only apply chart overrides when the chart layout is not customized already
        tvWidget.applyOverrides({
          'paneProperties.background': '#222222',
          'paneProperties.backgroundType': 'solid',
          "clear_price_scale_on_error_or_empty_bars": false,
          "show_interval_dialog_on_key_press": false,
          'mainSeriesProperties.candleStyle.upColor': '#0BA267',   // Custom bullish candle color
          'mainSeriesProperties.candleStyle.downColor': '#C5384A', // Custom bearish candle color
          'mainSeriesProperties.candleStyle.borderUpColor': '#0BA267',  // Border color for bullish candles
          'mainSeriesProperties.candleStyle.borderDownColor': '#C5384A', // Border color for bearish candles
          'mainSeriesProperties.candleStyle.wickUpColor': '#0BA267',    // Wick color for bullish candles
          'mainSeriesProperties.candleStyle.wickDownColor': '#C5384A',  // Wick color for bearish candles

        })
      }
      tvWidget.activeChart().setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      tvWidget.onContextMenu(function (unixTime, price) {
        const {
          selectedBalanceInfo: { quoteBalance = 0, baseBalance = 0 },
          currentSymbol: { exchange, pair },
          whatstrat: { TRADING_LIMIT = 0 },
        } = window;
        let buyAmount = Math.min(baseBalance, TRADING_LIMIT) / 1.005 / price;
        const coinmException = config?.exchanges?.[exchange]?.market === 'delivery' || config?.exchanges?.[exchange]?.market.includes('inverse') || ((config?.exchanges?.[exchange]?.market === 'swap' && !exchange.includes('bitget')) || (config?.exchanges?.[exchange]?.market === 'swap' && exchange.includes('bitget') && currentSymbol?.pair.split('-')[0] !== 'USDT'))
        if (coinmException) {
          buyAmount = TRADING_LIMIT
        }
        const requestData = {
          amt: parseFloat(buyAmount),
          price: price,
          exch: exchange,
          pair: pair,
        };

        let formattedPrice = tvWidget.activeChart().priceFormatter().format(price);
        let contextMenu = [
          { text: '-', position: 'top' },
          { text: '-Objects Tree...' },
          /*
          {
            text: 'Save strategy',
            click: function () {
              window.exportData();
            },
          },
          */
        ];
        const relevantQuoteBalance = coinmException ? Math.abs(quoteBalance) : quoteBalance
        const relevantBuyAmount = !coinmException ? parseFloat(buyAmount).toFixed(8) : parseFloat(buyAmount).toFixed(0)

        contextMenu.unshift(
          {
            text: `Sell ${relevantQuoteBalance}${coinmException ? ' contracts' : ''} @ ${parseFloat(formattedPrice).toFixed(8)}`,
            position: 'bottom',
            click() {
              requestData.amt = relevantQuoteBalance;
              Api.placeSellOrder(requestData);
            },
          },
          {
            text: `Sell ${relevantBuyAmount}${coinmException ? ' contracts' : ''} @ ${parseFloat(formattedPrice).toFixed(8)}`,
            position: 'bottom',
            click() {
              Api.placeSellOrder(requestData);
            },
          },
          {
            text: `Buy ${relevantBuyAmount}${coinmException ? ' contracts' : ''} @ ${parseFloat(formattedPrice).toFixed(8)}`,
            position: 'bottom',
            click() {
              Api.placeBuyOrder(requestData);
            },
          },
          {
            text: 'Toggle support / resistance lines visibility',
            position: 'bottom',
            click() {
              handleShowSRLines();
            },
          },
        );

        if (window?.whatstrat?.BUY_METHOD === 'sgsnextgen') {
          const strategyTL = window?.nextTL || 0

          contextMenu.unshift(
            {
              text: `Buy ${parseFloat(strategyTL / price).toFixed(8)} @ ${parseFloat(formattedPrice).toFixed(8)} (TL multiplier applied)`,
              position: 'bottom',
              click() {
                Api.placeBuyOrder({
                  amt: parseFloat(strategyTL / price),
                  price: price,
                  exch: exchange,
                  pair: pair,
                });
              }
            }
          )

        }
        return contextMenu;
      });

    });

    tvWidget.headerReady().then(() => {
      /*
          if (pnlButton === null) {
            const pnlButton = tvWidget.createButton();
            pnlButton.classList.add("apply-common-tooltip", 'button-2ioYhFEY', "isInteractive-20uLObIc");
            pnlButton.setAttribute("title", "PNL analysis");
            pnlButton.innerHTML = "<link rel=\"stylesheet\" href=\"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css\"><i class=\"fa fa-pie-chart\" aria-hidden=\"true\" style=\"color:#787b86;\"></i></link><span style=\"color:#787b86;\">&nbsp;PNL analysis</span>";
            pnlButton.addEventListener("click", () => {
              gotoRoute("/pnl");
            });
            setPnlButton(pnlButton);
          }
          */
      if (licenseType !== 'one' && !isMobile) {
        const alarmButton = tvWidget.createButton();
        alarmButton.setAttribute('title', 'Alerts (beta)');
        alarmButton.classList.add('apply-common-tooltip', 'button-2ioYhFEY', 'isInteractive-20uLObIc');
        alarmButton.innerHTML =
          '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><i class="fa fa-bell-o" aria-hidden="true" style="color:#d1d4dc;"/></i></link><span style="color:#d1d4dc;">&nbsp;Alerts</span>';
        alarmButton.addEventListener('click', () => {
          if (active && !startExport) {
            window.exportConditions = exportConditions
            window.exportConditions();
            noneStates.exportConditionTimer = null;
            setStartExport(true)
          }
          dispatch(ActionToggleAlerts());
        });
      }
      /*
      const button = tvWidget.createButton();
      button.setAttribute('title', 'Balances');
      button.classList.add('apply-common-tooltip');
      button.innerHTML =
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"><i class="fa fa-money" aria-hidden="true" style="color:#d1d4dc;"></i></link><span style="color:#d1d4dc;">&nbsp;Balances:</span>';
      */
      tvWidget.setCSSCustomProperty('--tv-color-toolbar-button-text-active', '#d1d4dc');


      setHeaderCreated(true);
      //const searchInput = button.ownerDocument.querySelector('#header-toolbar-symbol-search input');
      //searchInput.addEventListener('select', onSelect);

    });

    return function () {
      
      if (tvWidget) {
        tvWidget.remove();
        window.tvWidget = null;
        setHasStarted(false)
      }
        
      if (noneStates.exportConditionTimer) {
        clearTimeout(noneStates.exportConditionTimer);
      }
      dispatch(CustomDispatch('putState', false, 'isOpenSideBar'));
    };
  }, [symbols]);

  useEffect(() => {
    if (!currentSymbol) return;
    if (!symbols || symbols.length < 1) return;

    if (!props.history.location.pathname.includes('/chart') && !props.history.location.search.includes('type') && localStorage.getItem('appPair')?.split('-')?.length > 1) {
      // redirect to chart when visit is coming from app webview
      history.push('/chart');
    }
    if (!_.isNil(symbols) && symbols.length > 1 && window.tvWidget && datafeed) {
      datafeed.symbols = symbols;
      // if (selectedSymbol === symbols[0].symbol && [loading, ""].includes(currentSymbol.pair)) {
      // 	const symbolInfo = symbols[0];
      // 	setCurrentSymbol(symbolInfo);
      // }
      const appExchange = localStorage.getItem('appExchange');
      const appPair = localStorage.getItem('appPair');

      if (!currentSymbol.pair || currentSymbol.pair === loading || (!usedUrlParams && localStorage.getItem('appPair')?.split('-')?.length > 1)) {
        const elementPos = symbols.findIndex(x => x.pair === appPair && x.exchange === appExchange);
        const symbolInfo = elementPos > -1 ? symbols[elementPos] : symbols[0];
        if (isHeaderCreated && !_.isNil(window.tvWidget) && !_.isNil(datafeed) && elementPos > -1 && !_.isEqual(symbolInfo, lastSymbolSet)) {
          lastSymbolSet = symbolInfo
          setSymbol(symbolInfo);
          setUsedUrlParams(true)

        }

      }
      // set current symbol when selected pair changes, needed to set proper period
      else if (!_.isNil(currentSymbol) && !_.isNil(selectedSymbol) && isHeaderCreated) {
        const elementPos = symbols.findIndex(x => x.name === selectedSymbol);
        const symbolInfo = symbols[elementPos];

        if (!_.isNil(window.tvWidget)) {
          // Use onChartReady to ensure the widget is fully loaded
          window.tvWidget.onChartReady(() => {
            try {
              if (typeof window.tvWidget.activeChart === 'function') {
                const activeChart = window.tvWidget.activeChart();
                if (activeChart && typeof activeChart.symbol === 'function') {
                  const chartSymbol = activeChart.symbol();

                  if (Array.isArray(symbols) && symbols.length > 0 && typeof chartSymbol === 'string' && chartSymbol.length > 0) {
                    if (!_.isNil(symbolInfo) && !_.isEqual(symbolInfo, lastSymbolSet)) {
                      lastSymbolSet = symbolInfo
                      setSymbol(symbolInfo);

                    }
                  }
                } else {
                  console.error('activeChart is not an object or symbol is not a function.');
                }
              } else {
                console.error('activeChart is not a function.');
              }
            } catch (error) {
              console.error('An error occurred while accessing activeChart or its symbol:', error);
            }
          });
        } else {
          console.error('tvWidget is not defined.');
        }


      }
    }
  }, [symbols, selectedSymbol]);

  useEffect(() => {
    if (!isHeaderCreated || !currentSymbol.exchange || !currentSymbol.pair || !window.tvWidget) return;
    if (!currentSymbol) return;
    // forcefully set chart to first symbol in config on initial load
    if (isHeaderCreated && !_.isNil(symbols) && symbols.length > 0 && window.tvWidget) {
      const appPair = localStorage.getItem('appPair');
      if (_.isNil(appPair) && (!hasSetInitSymbol || currentSymbol?.pair === 'Loading') && !_.isEqual(symbols[0], lastSymbolSet)) {
        lastSymbolSet = symbols[0]

        setSymbol(symbols[0])
        setHasSetInitialSymbol(true)
      }
    }
  }, [isHeaderCreated, symbols, currentSymbol]);

  useEffect(() => {
    if (config?.pairs) {
      if (_.isNil(window.symbolPeriods)) {
        window.symbolPeriods = {};
      }

      Object.keys(config.pairs).forEach(exchange => {
        if (config.pairs[exchange]) {
          Object.keys(config.pairs[exchange]).forEach(pair => {
            const symbolName = `${pair} / ${exchange.toUpperCase()}`;
            let pairConfig = config.pairs[exchange][pair];
            if (pairConfig) {
              let pairStrategyConfig = config.strategies?.[pairConfig.strategy];
              let pairPeriod = 1440;
              if (!_.isNil(pairConfig.override?.PERIOD)) {
                pairPeriod = parseFloat(pairConfig.override.PERIOD);
              } else if (pairStrategyConfig && !_.isNil(pairStrategyConfig.PERIOD)) {
                pairPeriod = parseFloat(pairStrategyConfig.PERIOD);
              }
              window.symbolPeriods[symbolName] = pairPeriod;
            }
          });
        }
      });
    }
  }, [config]);


  // set and refresh header buttons
  useEffect(() => {
    if (!currentSymbol) return;
    if (!isHeaderCreated || !currentSymbol.exchange || !currentSymbol.pair || !window.tvWidget) return;
    if (!canSafelyInteractWithChart()) return;
    const balance = balances.find(item => item.exchange === currentSymbol.exchange && item.pair === currentSymbol.pair);
    if (!balance) return;

    let { baseBalance = 0, quoteBalance = 0 } = balance;
    let [base, quote] = currentSymbol.pair.split('-');

    const ftxException = currentSymbol.exchange.includes('ftx') && quote === 'PERP';
    const coinmException = config?.exchanges?.[currentSymbol.exchange]?.market === 'delivery' || config?.exchanges?.[currentSymbol.exchange]?.market.includes('inverse') || ((config?.exchanges?.[currentSymbol.exchange]?.market === 'swap' && !currentSymbol.exchange.includes('bitget')) || (config?.exchanges?.[currentSymbol.exchange]?.market === 'swap' && currentSymbol.exchange.includes('bitget') && base !== 'USDT'))

    if (ftxException && !_.isNil(pairData)) {
      baseBalance = pairData.quoteBalance;
      quoteBalance = pairData.walletBalance;
    }

    if (currentSymbol?.exchange.includes('bitget') && config.exchanges?.[exchange]?.market !== 'spot') {
      quoteBalance = pairData?.currentQty || 0
    }
    else if (currentSymbol?.exchange.includes('bitget') && config.exchanges?.[exchange]?.market === 'spot') {
      quoteBalance = pairData?.quoteBalance || 0
    }

    if (coinmException && !_.isNil(pairData)) {
      baseBalance = pairData.walletBalance
      quoteBalance = pairData.currentQty
      if (exchange.includes('binanceFutures') || exchange.includes('futures_gunthy')) {
        base = currentSymbol.pair.split('-')[1]
      }
      if (exchange.includes('okex5') && config.exchanges?.[exchange]?.market === 'swap') {
        base = 'USDT'
      }
      quote = 'Contracts'
    }

    if (currentSymbol.exchange.includes('dydx') || currentSymbol.exchange.includes('dydx4')) {
      baseBalance = pairData?.equity || 0
      quoteBalance = pairData?.currentQty || 0
    }

    if (localStorage.getItem('privacyMode') == 'true') {
      baseBalance = '●●●●●';
      quoteBalance = '●●●●●';
    }

    let baseButton = baseBalanceButton;
    try {
      if (baseBalanceButton === null) {
        baseButton = window.tvWidget.createButton();
        baseButton.classList.add('apply-common-tooltip');
        setBaseBalanceButton(baseButton);
      }
      baseButton.setAttribute('title', base + ' balance');
      baseButton.innerHTML =
        '<span style="color:#d1d4dc;">' +
        base +
        ': ' +
        baseBalance +
        '</span>';
    } catch (error) {
      //console.log('error making button 1')
    }


    let quoteButton = quoteBalanceButton;
    try {
      if (quoteBalanceButton === null) {
        quoteButton = window.tvWidget.createButton();
        quoteButton.classList.add('apply-common-tooltip');
        setQuoteBalanceButton(quoteButton);
      }
      quoteButton.setAttribute('title', quote + ' balance');
      quoteButton.innerHTML =
        '<span style="color:#d1d4dc;">' +
        quote +
        ': ' +
        quoteBalance +
        '</span>';
    } catch (error) {
      //console.log('error making button 2')
    }


    quoteBalance < 0 ? setPosition('SHORT') : setPosition('LONG');
  }, [balances, currentSymbol, pairData]);

  useEffect(() => {
    if (!currentSymbol) return;
    if (!whatstrat) return;
    let indicatorData = {
      BUY_METHOD: 'gain',
      SELL_METHOD: 'gain',
      EMASPREAD: 0,
      EMA1: 0,
      EMA2: 0,
      EMA3: 0,
      ADX_ENABLED: false,
      DI_PERIOD: 0,
      ATR_PERIOD: 0,
      SMAPERIOD: 0,
      STDV: 0,
      TENKAN_PERIOD: 0,
      KIJUN_PERIOD: 0,
      SENKOUSPAN_PERIOD: 0,
      DISPLACEMENT: 0,
      MACD_SHORT: 0,
      MACD_LONG: 0,
      MACD_SIGNAL: 0,
      SLOW_SMA: 0,
      FAST_SMA: 0,
      MFI_LENGTH: 0,
      STOCHRSI_LENGTH: 0,
      STOCH_K: 0,
      SLOW_STOCH_K: 0,
      STOCH_D: 0,
      MFI_ENABLED: false,
      RSI_BUY_ENABLED: false,
      RSI_SELL_ENABLED: false,
      STOCHRSI_ENABLED: false,
      STOCH_ENABLED: false,
      MFI_BUY_LEVEL: 0,
      MFI_SELL_LEVEL: 0,
      RSI_BUY_LEVEL: 0,
      RSI_SELL_LEVEL: 0,
      STOCHRSI_BUY_LEVEL: 0,
      STOCHRSI_SELL_LEVEL: 0,
      STOCH_SELL_LEVEL: 0,
      STOCH_BUY_LEVEL: 0,
      RSI_LENGTH: 0,
    };

    if (!_.isNil(whatstrat)) {
      indicatorData = {
        BUY_METHOD: whatstrat.BUY_METHOD,
        SELL_METHOD: whatstrat.SELL_METHOD,
        EMASPREAD: whatstrat.EMASPREAD,
        EMA1: whatstrat.EMA1,
        EMA2: whatstrat.EMA2,
        EMA3: whatstrat.EMA3,
        ADX_ENABLED: whatstrat.ADX_ENABLED,
        DI_PERIOD: whatstrat.DI_PERIOD,
        ATR_PERIOD: whatstrat.ATR_PERIOD,
        SMAPERIOD: whatstrat.SMAPERIOD,
        STDV: whatstrat.STDV,
        TENKAN_PERIOD: whatstrat.TENKAN_PERIOD,
        KIJUN_PERIOD: whatstrat.KIJUN_PERIOD,
        SENKOUSPAN_PERIOD: whatstrat.SENKOUSPAN_PERIOD,
        DISPLACEMENT: whatstrat.DISPLACEMENT,
        MACD_SHORT: whatstrat.MACD_SHORT,
        MACD_LONG: whatstrat.MACD_LONG,
        MACD_SIGNAL: whatstrat.MACD_SIGNAL,
        SLOW_SMA: whatstrat.SLOW_SMA,
        FAST_SMA: whatstrat.FAST_SMA,
        MFI_LENGTH: whatstrat.MFI_LENGTH,
        STOCHRSI_LENGTH: whatstrat.STOCHRSI_LENGTH,
        STOCH_K: whatstrat.STOCH_K,
        SLOW_STOCH_K: whatstrat.SLOW_STOCH_K,
        STOCH_D: whatstrat.STOCH_D,
        MFI_ENABLED: whatstrat.MFI_ENABLED,
        RSI_BUY_ENABLED: whatstrat.RSI_BUY_ENABLED,
        RSI_SELL_ENABLED: whatstrat.RSI_SELL_ENABLED,
        STOCHRSI_ENABLED: whatstrat.STOCHRSI_ENABLED,
        STOCH_ENABLED: whatstrat.STOCH_ENABLED,
        MFI_BUY_LEVEL: whatstrat.MFI_BUY_LEVEL,
        MFI_SELL_LEVEL: whatstrat.MFI_SELL_LEVEL,
        RSI_BUY_LEVEL: whatstrat.RSI_BUY_LEVEL,
        RSI_SELL_LEVEL: whatstrat.RSI_SELL_LEVEL,
        STOCHRSI_BUY_LEVEL: whatstrat.STOCHRSI_BUY_LEVEL,
        STOCHRSI_SELL_LEVEL: whatstrat.STOCHRSI_SELL_LEVEL,
        STOCH_SELL_LEVEL: whatstrat.STOCH_SELL_LEVEL,
        STOCH_BUY_LEVEL: whatstrat.STOCH_BUY_LEVEL,
        RSI_LENGTH: whatstrat.RSI_LENGTH,
        oneSCALPER: whatstrat.oneSCALPER,
        EWO: whatstrat.EWO,
        MM_TENKAN: whatstrat.MM_TENKAN,
      };
    }
    setPairStrat(indicatorData)
  }, [currentSymbol]);

  useEffect(() => {
    if (!currentSymbol) return;
    const { exchange, pair } = currentSymbol;
    const key = exchange + '/' + pair;
    if (_.isNil(lastOrders[key])) {
      setLastOrders({ ...lastOrders, key: 0 });
    }
  }, [currentSymbol]);

  useEffect(() => {
    if (!pairData) return;
    const newNotifications = pairData.notifications;

    newNotifications.forEach(element => {
      sendNotification(element.text, element.variant, element.persist, false, element.showLonger);
    });
  }, [pairData]);

  useEffect(() => {
    if (!currentSymbol) return;
    let text, price, time;
    if (!pairData || !isHeaderCreated || !window.tvWidget) return;
    // prevent setting 0 values as targets
    if (!whatstrat || _.isNil(pairData.TSSL) || _.isNil(whatstrat.BUY_METHOD)) return;
    if (!canSafelyInteractWithChart()) return;
    {
      const {
        openOrders = [],
        ABP,
        priceToBuy,
        priceToSell,
        STOP,
        spotGridBuydown,
        sellTrailingTarget,
        buyTrailingTarget,
        firstTradeBuyTrailingTarget,
        spotGridSellTarget,
        futuresGridCloseTarget,
        sellTrailingTargetShort,
        buyTrailingTargetShort,
        firstTradeShortTrailingTarget,
        customSellTarget,
        customBuyTarget,
        customStopTarget,
        customCloseTarget,
        customTrailingTarget,
        customDcaTarget,
        customChartTargets = [],
        customChartShapes = [],
      } = pairData;

      let {
        limitupbuy,
        limitdown,
        limitupbuyTM,
        limitdownTM,
        limitupDUbuy,
        latestBuyRate,
        ROEdownSHORTLONG,
        limitupRTbuy,
        limitdownRTSELL,
      } = pairData.TSSL;

      const { exchange, pair } = currentSymbol;

      if (_.isNil(window.primitives)) {
        window.primitives = []
      }
      if (_.isNil(window.shapes)) {
        window.shapes = []
      }

      if (pair === window.lastChartPair && exchange === window.lastChartExchange) {
        if (!_.isNil(window[exchange])) {
          if (!_.isNil(window[exchange][pair])) {
            let data = !_.isNil(window[exchange][pair].primitives) ? window[exchange][pair].primitives : [];
            window.primitives = data;
          }
        }
      } else {
        let temp = [];
        window.primitives.forEach(primitive => {
          if (typeof primitive === 'object' && !_.isNil(primitive)) {
            try {
              primitive.remove();
            } catch (error) {
              // console.log(error);
              //console.log('silent catch2', error)
            }
          } else {
            temp.push(primitive);
          }
        });
        window.primitives = temp;
      }

      let buy_method_low = whatstrat?.BUY_METHOD.toLowerCase();
      let sell_method_low = whatstrat?.SELL_METHOD.toLowerCase();
      let notGridStrat = !whatstrat?.SELL_METHOD.toLowerCase().includes('grid');

      let isMargin = false;
      if (
        [
          'bitmex',
          'bitmex_testnet',
          'krakenFutures',
          'binanceFutures',
          'dydx',
          'dydx4',
          'futures_gunthy',
          'kumex',
        ].includes(exchange) || (exchange.includes('bybit') && !bybitMarket.includes('spot')) ||
        (exchange.includes('okgunbot') && okgbMarket !== 'spot') ||
        (exchange.includes('okex5') && okexMarket !== 'spot') ||
        (exchange.includes('bitget') && whatstrat.IS_MARGIN_STRAT === true) ||
        (exchange.includes('ftx') && whatstrat.IS_MARGIN_STRAT === true)
      ) {
        isMargin = true;
      }

      let newLineData = {}
      const needLegacyLines = !['sgsfutures', 'sgsnextgen', 'channelmaestro'].includes(buy_method_low)
      const isNotLegacyStrat = buy_method_low.includes('orderflow') || buy_method_low.includes('candlespattern') || buy_method_low.includes('grid') || buy_method_low.includes('sgs') || buy_method_low.includes('custom') || buy_method_low.includes('channelmaestro')

      // prepare legacy line data
      if (needLegacyLines) {
        if (!_.isNil(priceToBuy) &&
          (// spot conditions to hide price to buy
            (whatstrat.IS_MARGIN_STRAT === false &&
              ['adx', 'atrts', 'bbta', 'emaspread', 'macd', 'smacross', 'ichimoku', 'tsa', 'custom'].includes(
                buy_method_low,
              )) ||
            (whatstrat.IS_MARGIN_STRAT === false &&
              buy_method_low !== 'pp' &&
              (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid >
              parseFloat(whatstrat.MIN_VOLUME_TO_SELL)) ||
            pairData.reversal === true ||
            whatstrat.bitRage === true) !== true &&
          (// margin conditions to hide long at
            (whatstrat.IS_MARGIN_STRAT === true &&
              ['adx', 'atrts', 'bbta', 'emaspread', 'macd', 'macdh', 'smacross', 'ichimoku', 'tsa', 'custom'].includes(
                buy_method_low,
              )) ||
            (whatstrat.IS_MARGIN_STRAT === true &&
              buy_method_low !== 'market_maker' &&
              buy_method_low !== 'pp' &&
              pairData.avgEntryPrice !== 0) ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.bitRage === true) ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.oneSCALPER === true && buy_method_low === 'market_maker') ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.EWO === true && buy_method_low === 'market_maker') ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.PULLBACK === true && buy_method_low === 'market_maker') ||
            (whatstrat.IS_MARGIN_STRAT === true &&
              whatstrat.GRID === true &&
              buy_method_low === 'market_maker' &&
              whatstrat.PULLBACK === false &&
              whatstrat.MOTION_OF_THE_OCEAN === false &&
              whatstrat.oneSCALPER === false &&
              whatstrat.EWO === false &&
              whatstrat.SupportResistance === false)
          ) !== true &&
          !['spotgrid', 'spotgridadvanced', 'futuresgrid', 'stepgridhybrid', 'stepgrid', 'sgsfutures'].includes(buy_method_low) &&
          notGridStrat
        ) {
          const text = buy_method_low === 'pp' ? 'Pingpong buy' : 'Buy at'
          const onMove = buy_method_low !== 'pp' ? false : function () {
            dispatch(
              CustomDispatch(
                'putConfig',
                this._line._points[0].price,
                'PP_BUY',
                'override',
                pair,
                exchange,
                'pairs',
              ),
            );
          }
          newLineData.entry = {
            price: priceToBuy,
            label: text,
            length: 0,
            style: 2,
            color: 'rgba(24, 124, 50, 1)',
            onMove: onMove
          }
        }

        if (!_.isNil(priceToSell) &&
          (// spot conditions to hide exit point
            (whatstrat.IS_MARGIN_STRAT === false &&
              whatstrat.DOUBLE_CHECK_GAIN === false &&
              ['adx', 'bbta', 'emaspread', 'macd', 'macdh', 'smacross', 'ichimoku', 'custom'].includes(sell_method_low)) ||
            (whatstrat.IS_MARGIN_STRAT === false &&
              whatstrat.DOUBLE_CHECK_GAIN === true &&
              ['custom'].includes(sell_method_low)) ||
            (whatstrat.IS_MARGIN_STRAT === false &&
              sell_method_low !== 'pp' &&
              pairData.reversal === false &&
              (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid <
              parseFloat(whatstrat.MIN_VOLUME_TO_SELL)) ||
            whatstrat.bitRage === true) !== true &&
          (// margin conditions to hide short at
            (whatstrat.IS_MARGIN_STRAT === true &&
              ['adx', 'atrts', 'bbta', 'emaspread', 'macd', 'macdh', 'smacross', 'ichimoku', 'tsa', 'custom'].includes(
                sell_method_low,
              )) ||
            (whatstrat.IS_MARGIN_STRAT === true &&
              sell_method_low !== 'market_maker' &&
              sell_method_low !== 'pp' &&
              pairData.avgEntryPrice !== 0) ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.bitRage === true) ||
            (whatstrat.IS_MARGIN_STRAT === true && whatstrat.PULLBACK === true && sell_method_low === 'market_maker') ||
            (whatstrat.IS_MARGIN_STRAT === true &&
              whatstrat.GRID === true &&
              sell_method_low === 'market_maker' &&
              whatstrat.PULLBACK === false &&
              whatstrat.MOTION_OF_THE_OCEAN === false &&
              whatstrat.SupportResistance === false)) !== true && notGridStrat

        ) {
          const onMove = sell_method_low !== 'pp' ? function () {
            dispatch(
              CustomDispatch(
                'putConfig',
                ((this._line._points[0].price - ABP) / ABP) * 100,
                'GAIN',
                'override',
                pair,
                exchange,
                'pairs',
              ),
            );
          } : function () {
            dispatch(
              CustomDispatch(
                'putConfig',
                this._line._points[0].price,
                'PP_SELL',
                'override',
                pair,
                exchange,
                'pairs',
              ),
            );
          }
          const moveHandler = (sell_method_low !== 'pp' && sell_method_low !== 'futuresgrid' && sell_method_low !== 'sgsfutures' && whatstrat.IS_MARGIN_STRAT === true && spotGridSellTarget === 0 && notGridStrat) === true ? null : onMove
          const text = _.isNil(moveHandler) ? 'Sell at' : sell_method_low === 'pp' ? 'Pingpong sell' : 'Sell target'

          newLineData.exit = {
            price: priceToSell,
            label: text,
            length: 0,
            style: 2,
            onMove: moveHandler,
            color: 'rgba(228, 27, 27, 1)',
          }
        }

        if (!_.isNil(pairData['avgEntryPrice']) && isMargin && config.bot?.SHOW_ENTRY_LINE !== false) {
          newLineData.ABP = {
            price: pairData['avgEntryPrice'],
            amount: 0,
            label: "Entry price",
            length: 100,
            style: 0,
            color: 'rgba(24, 124, 50, 1)',
          }
        }
        else if (!_.isNil(pairData['ABP']) && config.bot?.SHOW_ENTRY_LINE !== false &&
          (pairData.reversal === true ||
            (whatstrat.IS_MARGIN_STRAT === false &&
              (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid >
              parseFloat(whatstrat.MIN_VOLUME_TO_SELL)) === true) &&
          (
            (whatstrat?.BUY_METHOD.toLowerCase() === 'stepgridhybrid' ||
              whatstrat?.BUY_METHOD.toLowerCase() === 'stepgridscalp' ||
              whatstrat?.BUY_METHOD.toLowerCase() === 'sgsnextgen') &&
            whatstrat?.DYNAMIC_EXIT_LOGIC === true
          ) !== true
        ) {
          let text = 'Break even'
          if (!_.isNil(pairData)) {
            if (whatstrat?.unit_cost === true) {
              text = 'Unit cost';
            }
          }
          newLineData.ABP = {
            price: pairData['ABP'],
            amount: 0,
            label: text,
            length: 100,
            style: 0,
            color: 'rgba(24, 124, 50, 1)',
          }
        }

        if (!_.isNil(pairData['avgEntryPrice_s']) && isMargin && config.bot?.SHOW_ENTRY_LINE !== false) {
          newLineData.ABP_s = {
            price: pairData['avgEntryPrice_s'] || 0,
            amount: 0,
            label: "Entry price (short)",
            length: 100,
            style: 0,
            color: 'rgba(228, 27, 27, 1)',
          }
        }

        // set stop limit target as price
        if (whatstrat.IS_MARGIN_STRAT === false &&
          !_.isNil(newLineData.ABP) &&
          pairData.reversal === false &&
          ![
            'custom',
            'spotgrid',
            'spotgridadvanced',
            'futuresgrid',
            'sgsfutures',
            'stepgridhybrid',
            'stepgrid',
            'stepgridhedge',
            'stepgridscalp',
            'sgsnextgen',
            'channelmaestro'
          ].includes(buy_method_low)
        ) {
          newLineData.stopLimit = {
            price: pairData.stoplimitprice || 0,
            amount: 0,
            label: "Stop limit",
            length: 0,
            style: 0,
            onMove: function () {
              dispatch(
                CustomDispatch(
                  'putConfig',
                  ((ABP - this._line._points[0].price) / ABP) * 100,
                  'STOP_LIMIT',
                  'override',
                  pair,
                  exchange,
                  'pairs',
                ),
              );
            },
            color: 'rgba(228, 27, 27, 1)',
          }
        }

        // set initial RT sell price (before pair entered RT)
        if (pairData.reversal === false && whatstrat.RT_ENABLED === true && !_.isNil(newLineData.ABP)) {
          newLineData.initialRtSell = {
            price: ABP * (1 - whatstrat.RT_GAIN / 100) || 0,
            amount: 0,
            label: "RT SELL at ",
            length: 0,
            style: 2,
            color: 'rgba(228, 27, 27, 1)',
          }
        }

        // set RT sell price
        if (pairData.reversal === true &&
          whatstrat.IS_MARGIN_STRAT === false &&
          (pairData.weboughtreverted === true || pairData.justbuyback === true)) {
          newLineData.rtSell = {
            price: pairData.pricetortsell,
            amount: 0,
            label: "RT SELL at ",
            length: 0,
            style: 2,
            color: 'rgba(228, 27, 27, 1)',
          }
        }

        // set du buydown target as price
        if (whatstrat.IS_MARGIN_STRAT === false &&
          !_.isNil(newLineData.ABP) &&
          whatstrat.DOUBLE_UP === true &&
          pairData.reversal === false &&
          pairData.quoteBalance * pairData.Ask > parseFloat(whatstrat.MIN_VOLUME_TO_SELL) &&
          pairData.dudone === false) {
          const lastBuyPrice = orders?.[0]?.rate || 0
          newLineData.duBuydown = {
            price: lastBuyPrice * (1 - whatstrat.DU_BUYDOWN / 100) || 0,
            amount: `${parseFloat(whatstrat.DU_BUYDOWN).toFixed(2)} %`,
            label: "DCA buydown",
            length: 0,
            style: 0,
            onMove: function () {
              dispatch(
                CustomDispatch(
                  'putConfig',
                  ((lastBuyPrice - this._line._points[0].price) / lastBuyPrice) * 100,
                  'DU_BUYDOWN',
                  'override',
                  pair,
                  exchange,
                  'pairs',
                ),
              );
            },
            color: 'rgba(34, 116, 165, 1)',
          }
        }

        // set tssl buy stop
        if ((_.isNil(newLineData.entry) ||
          (!_.isNil(newLineData.entry) && pairData.Ask > newLineData.entry?.price) ||
          whatstrat.IS_MARGIN_STRAT === true ||
          buy_method_low !== 'tssl' ||
          pairData.reversal === true ||
          (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid >
          parseFloat(whatstrat.MIN_VOLUME_TO_SELL) ||
          whatstrat.bitRage === true) !== true && limitupbuy !== false && limitupbuy !== 0
        ) {
          newLineData.limitupbuy = {
            price: limitupbuy || 0,
            amount: `${parseFloat(whatstrat.BUY_RANGE).toFixed(2)} %`,
            label: "Buy trailing stop (tssl)",
            length: 10,
            style: 2,
            color: 'rgba(34, 116, 165, 1)',
          }
        }

        // set tssl sell stop
        if (((!_.isNil(newLineData.exit) && pairData.Bid < newLineData.exit?.price) ||
          whatstrat.IS_MARGIN_STRAT === true ||
          sell_method_low !== 'tssl' ||
          (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid <
          parseFloat(whatstrat.MIN_VOLUME_TO_SELL) ||
          whatstrat.bitRage === true) !== true && limitdown !== false && limitdown !== 0
        ) {
          newLineData.limitdown = {
            price: limitdown || 0,
            amount: `${parseFloat(whatstrat.SELL_RANGE).toFixed(2)} %`,
            label: 'Sell trailing stop (tssl)',
            length: 10,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }

        // set ROE scalper stop
        if (parseFloat(STOP) > 0 && pairData.ROE > parseFloat(whatstrat.ROE) && pairData.currentQty != 0) {
          newLineData.roeScalperStop = {
            price: pairData.currentQty > 0
              ? // approximate price of long ROE trailing target
              ((pairData.Bid - pairData.avgEntryPrice) / pairData.ROE) * STOP + pairData.avgEntryPrice
              : // approximate price of short ROE trailing target
              pairData.avgEntryPrice - ((pairData.avgEntryPrice - pairData.Ask) / pairData.ROE) * STOP,
            amount: `${parseFloat(whatstrat.SELL_RANGE).toFixed(2)} %`,
            label: 'ROE scalper stop',
            length: 10,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }

        // set trailme buy stop
        if (((whatstrat.IS_MARGIN_STRAT === false &&
          whatstrat.TRAIL_ME_BUY === true &&
          (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Ask >
          parseFloat(whatstrat.MIN_VOLUME_TO_SELL)) ||
          whatstrat.IS_MARGIN_STRAT === true ||
          pairData.reversal === true ||
          whatstrat.TRAIL_ME_BUY === false ||
          whatstrat.bitRage === true) !== true
        ) {
          newLineData.limitupbuyTM = {
            price: limitupbuyTM || 0,
            amount: `${parseFloat(whatstrat.TRAIL_ME_BUY_RANGE).toFixed(2)} %`,
            label: 'Buy trailing stop (tm)',
            length: 10,
            style: 2,
            color: 'rgba(34, 116, 165, 1)',
          }
        }

        // set trailme rt buy stop
        if ((pairData.reversal === false ||
          whatstrat.TRAIL_ME_RT === false ||
          pairData.wesoldreverted === false ||
          whatstrat.bitRage === true) !== true
        ) {
          newLineData.limitupRTbuy = {
            price: limitupRTbuy || 0,
            amount: `${parseFloat(whatstrat.TRAIL_ME_BUY_RANGE).toFixed(2)} %`,
            label: "RT Buy trailing stop",
            length: 10,
            style: 2,
            color: 'rgba(34, 116, 165, 1)',
          }
        }

        // set rt sell up target / range
        if (pairData.reversal === true &&
          whatstrat.TM_RT_SELL === true &&
          pairData.weboughtreverted === true
        ) {
          let target = 0
          // last buy price + RT_SELL_UP
          if (pairData.Bid < (1 + parseFloat(whatstrat.RT_SELL_UP)) * latestBuyRate) {
            target = (1 + parseFloat(whatstrat.RT_SELL_UP)) * latestBuyRate;
          }
          // or limitdownRTSELL
          else if (pairData.Bid > (1 + parseFloat(whatstrat.RT_SELL_UP)) * latestBuyRate) {
            target = limitdownRTSELL;
          }
          newLineData.rtSellUp = {
            price: target,
            amount: 0,
            label: "RT SELL UP trailing stop",
            length: 100,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }

        // set rt buy up target
        if (pairData.reversal === true &&
          whatstrat.IS_MARGIN_STRAT === false &&
          pairData.wesoldreverted === true &&
          parseFloat(whatstrat.RT_BUY_UP_LEVEL) > 0 &&
          (1 + parseFloat(whatstrat.RT_BUY_UP_LEVEL)) * pairData.latestSellRate < pairData['ABP']
        ) {
          newLineData.rtBuyUp = {
            price: (1 + parseFloat(whatstrat.RT_BUY_UP_LEVEL)) * pairData.latestSellRate || 0,
            amount: 0,
            label: "RT BUY UP at",
            length: 0,
            style: 1,
            color: 'rgba(24, 124, 50, 1)',
          }
        }

        // set trailme sell stop
        if (((whatstrat.IS_MARGIN_STRAT === false &&
          whatstrat.TRAIL_ME_SELL === true &&
          (pairData.quoteBalance - parseFloat(whatstrat.KEEP_QUOTE)) * pairData.Bid <
          parseFloat(whatstrat.MIN_VOLUME_TO_SELL)) ||
          whatstrat.IS_MARGIN_STRAT === true ||
          whatstrat.TRAIL_ME_SELL === false ||
          whatstrat.bitRage === true) !== true && limitdownTM !== false && limitdownTM !== 0
        ) {
          newLineData.limitdownTM = {
            price: limitdownTM || 0,
            amount: `${parseFloat(whatstrat.TRAIL_ME_SELL_RANGE).toFixed(2)} %`,
            label: "Sell trailing stop (tm)",
            length: 10,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }

        // set trailme DU stop
        if (((whatstrat.IS_MARGIN_STRAT === false &&
          whatstrat.DOUBLE_UP === true &&
          whatstrat.TRAIL_ME_DU === true &&
          pairData.Ask > latestBuyRate * (1 - whatstrat.DU_BUYDOWN / 100)) ||
          whatstrat.IS_MARGIN_STRAT === true ||
          whatstrat.DOUBLE_UP === false ||
          whatstrat.TRAIL_ME_DU === false ||
          pairData.reversal === true ||
          pairData.dudone === true ||
          pairData.quoteBalance * pairData.Ask < parseFloat(whatstrat.MIN_VOLUME_TO_SELL) ||
          whatstrat.bitRage === true) !== true && limitupDUbuy !== false && limitupDUbuy !== 0
        ) {
          newLineData.limitupDUbuy = {
            price: limitupDUbuy || 0,
            amount: `${parseFloat(whatstrat.TRAIL_ME_BUY_RANGE).toFixed(2)} %`,
            label: "DCA trailing stop",
            length: 10,
            style: 2,
            color: 'rgba(34, 116, 165, 1)',
          }
        }

        // set roe trailing stop
        if ((pairData.ROE < whatstrat.ROE * 0.8 ||
          whatstrat.IS_MARGIN_STRAT === false ||
          whatstrat.ROE_TRAILING === false ||
          whatstrat.ROE_SCALPER === true ||
          whatstrat.oneSCALPER === true ||
          whatstrat.EWO === true ||
          ROEdownSHORTLONG === false ||
          whatstrat.bitRage === true ||
          whatstrat.PND === true) !== true &&
          pairData.currentQty !== 0 && ROEdownSHORTLONG !== false && ROEdownSHORTLONG !== 0
        ) {
          const target = pairData.currentQty > 0
            ? // approximate price of long ROE trailing target
            ((pairData.Bid - pairData.avgEntryPrice) / pairData.ROE) * ROEdownSHORTLONG + pairData.avgEntryPrice
            : // approximate price of short ROE trailing target
            pairData.avgEntryPrice -
            ((pairData.avgEntryPrice - pairData.Ask) / pairData.ROE) * ROEdownSHORTLONG
          newLineData.ROEdownSHORTLONG = {
            price: target || 0,
            amount: `${parseFloat(whatstrat.ROE_LIMIT).toFixed(2)} %`,
            label: "ROE trailing stop",
            length: 10,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }

        if ((pairData.ROE < whatstrat.ROE * 0.8 ||
          whatstrat.IS_MARGIN_STRAT === false ||
          whatstrat.ROE_TRAILING === false ||
          whatstrat.ROE_SCALPER === true ||
          whatstrat.oneSCALPER === true ||
          whatstrat.EWO === true ||
          ROEdownSHORTLONG === false ||
          whatstrat.bitRage === true ||
          whatstrat.PND === true) !== true &&
          pairData.currentQty !== 0 && ROEdownSHORTLONG !== false && ROEdownSHORTLONG !== 0
        ) {
          const target = pairData.currentQty > 0
            ? // approximate price of long ROE trailing target
            ((pairData.Bid - pairData.avgEntryPrice) / pairData.ROE) * ROEdownSHORTLONG + pairData.avgEntryPrice
            : // approximate price of short ROE trailing target
            pairData.avgEntryPrice -
            ((pairData.avgEntryPrice - pairData.Ask) / pairData.ROE) * ROEdownSHORTLONG
          newLineData.ROEdownSHORTLONG = {
            price: target || 0,
            amount: `${parseFloat(whatstrat.ROE_LIMIT).toFixed(2)} %`,
            label: "ROE trailing stop",
            length: 10,
            style: 2,
            color: 'rgba(245, 187, 0, 1)',
          }
        }
      }

      // legacy lines without conditionals
      if (spotGridBuydown !== 0 && isNotLegacyStrat) {
        newLineData.spotGridBuydown = {
          price: spotGridBuydown,
          amount: 0,
          label: "Grid DCA target",
          length: 0,
          style: 0,
          color: 'rgba(34, 116, 165, 1)',
        }
      }

      if (spotGridSellTarget !== 0 && isNotLegacyStrat) {
        newLineData.spotGridSellTarget = {
          price: spotGridSellTarget,
          amount: 0,
          label: "Grid sell target",
          length: 0,
          style: 0,
          color: 'rgba(228, 27, 27, 1)',
        }
      }

      if (futuresGridCloseTarget !== 0 && isNotLegacyStrat) {
        newLineData.futuresGridCloseTarget = {
          price: futuresGridCloseTarget,
          amount: 0,
          label: "CLOSE at (fg)",
          length: 0,
          style: 0,
          color: 'rgba(228, 27, 27, 1)',
        }
      }

      if (sellTrailingTarget !== 0 && isNotLegacyStrat) {
        newLineData.sellTrailingTarget = {
          price: sellTrailingTarget,
          amount: 0,
          label: "Grid sell trailing",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (sellTrailingTargetShort !== 0 && isNotLegacyStrat) {
        newLineData.sellTrailingTargetShort = {
          price: sellTrailingTargetShort,
          amount: 0,
          label: "Grid sell trailing (fg)",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (buyTrailingTarget !== 0 && isNotLegacyStrat) {
        newLineData.buyTrailingTarget = {
          price: buyTrailingTarget,
          amount: 0,
          label: "Grid buy trailing",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (buyTrailingTargetShort !== 0 && isNotLegacyStrat) {
        newLineData.buyTrailingTargetShort = {
          price: buyTrailingTargetShort,
          amount: 0,
          label: "Grid buy trailing (fg)",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (firstTradeBuyTrailingTarget !== 0 && isNotLegacyStrat) {
        newLineData.firstTradeBuyTrailingTarget = {
          price: firstTradeBuyTrailingTarget,
          amount: 0,
          label: "New position trailing",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (firstTradeShortTrailingTarget !== 0 && isNotLegacyStrat) {
        newLineData.firstTradeShortTrailingTarget = {
          price: firstTradeShortTrailingTarget,
          amount: 0,
          label: "New position trailing (fg)",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (customBuyTarget !== 0 && isNotLegacyStrat) {
        newLineData.customBuyTarget = {
          price: customBuyTarget,
          amount: 0,
          label: "Buy target (c)",
          length: 0,
          style: 2,
          color: 'rgba(24, 124, 50, 1)',
        }
      }

      if (customDcaTarget !== 0 && isNotLegacyStrat) {
        newLineData.customDcaTarget = {
          price: customDcaTarget,
          amount: 0,
          label: "DCA target (c)",
          length: 10,
          style: 0,
          color: 'rgba(34, 116, 165, 1)',
        }
      }

      if (customSellTarget !== 0 && isNotLegacyStrat) {
        newLineData.customSellTarget = {
          price: customSellTarget,
          amount: 0,
          label: "Sell target (c)",
          length: 0,
          style: 0,
          color: 'rgba(228, 27, 27, 1)',
        }
      }

      if (customCloseTarget !== 0 && isNotLegacyStrat) {
        newLineData.customCloseTarget = {
          price: customCloseTarget,
          amount: 0,
          label: "Close target (c)",
          length: 0,
          style: 0,
          color: 'rgba(228, 27, 27, 1)',
        }
      }

      if (customTrailingTarget !== 0 && isNotLegacyStrat) {
        newLineData.customTrailingTarget = {
          price: customTrailingTarget,
          amount: 0,
          label: "Trailing target (c)",
          length: 10,
          style: 2,
          color: 'rgba(245, 187, 0, 1)',
        }
      }

      if (customStopTarget !== 0 && isNotLegacyStrat) {
        newLineData.customStopTarget = {
          price: customStopTarget,
          amount: 0,
          label: "Stop target (c)",
          length: 0,
          style: 0,
          color: 'rgba(228, 27, 27, 1)',
        }
      }

      // Schema for chart line lenghts
      // sup/res & break even: setLineLength(100)
      // order lines: setLineLength(20)
      // trailing lines: setLineLength(10)
      // all other targets: setLineLength(0)
      // make sure to use unique bodyText per line type
      let usedPrimitiveIds = [];
      let newPrimitives = [];
      let newShapeIds = []
      for (let index in openOrders) {
        if (openOrders.hasOwnProperty(index) && config.bot?.SHOW_OPEN_ORDERS !== false) {
          const order = openOrders[index];
          time = +new Date(order.time / 1000);
          price = order.rate;
          const setBodyBackgroundColor = 'rgba(255, 255, 255, 1)';
          text = 'BUY - id:' + order.id;
          let shape = 'arrow_up';
          let channel = 'low';
          let setBodyBorderColor = 'rgba(173, 209, 182, 1)';
          let setQuantityBackgroundColor = 'rgba(173, 209, 182, 1)';
          let setLineColor = 'rgba(173, 209, 182, 1)';
          let setBodyTextColor = 'rgba(0, 0, 0, 1)';
          let setQuantityTextColor = 'rgba(0, 0, 0, 1)';
          if (order.type === 'sell' || order.type === 'SELL') {
            text = 'SELL - id:' + order.id;
            shape = 'arrow_down';
            channel = 'high';
            setBodyBorderColor = 'rgba(228, 27, 27, 1)';
            setQuantityBackgroundColor = 'rgba(228, 27, 27, 1)';
            setLineColor = 'rgba(228, 27, 27, 1)';
            setBodyTextColor = 'rgba(228, 27, 27, 1)';
            setQuantityTextColor = 'rgba(255, 255, 255, 1)';
          }
          let orderPrimitive = _.find(window.primitives, item => item?._data?.bodyText === text);
          if (!_.isNil(orderPrimitive) && !_.isNil(orderPrimitive?._line) && !_.isNil(orderPrimitive?._line?._id)) {
            const prevPrice = orderPrimitive.getPrice()
            const prevQuantity = orderPrimitive.getQuantity()
            if (parseFloat(price) === parseFloat(prevPrice) && ((parseFloat(order.amount) === parseFloat(prevQuantity)) || (isNaN(parseFloat(prevQuantity) && isNaN(parseFloat(order?.amount)))))) {
              // nothing to change, only store privitive id for future reference
              usedPrimitiveIds.push(orderPrimitive?._line?._id);
            }
            else {
              orderPrimitive.setPrice(price);
              orderPrimitive.setQuantity(order.amount);
              usedPrimitiveIds.push(orderPrimitive?._line?._id);
            }
          } else {
            try {
              let primitive = window.tvWidget
                .activeChart()
                .createOrderLine()
                .onCancel('onCancel called', () => {
                  onCancelOrder(exchange, pair, order.id, order.type, order.rate);
                  sendNotification('Order canceled', 'info', false, false, false);
                })
                .setText(text)
                .setQuantity(order.amount)
                .setPrice(price)
                .setExtendLeft(true)
                .setLineStyle(0)
                .setLineLength(20)
                .setBodyBackgroundColor(setBodyBackgroundColor)
                .setBodyBorderColor(setBodyBorderColor)
                .setQuantityBackgroundColor(setQuantityBackgroundColor)
                .setLineColor(setLineColor)
                .setBodyTextColor(setBodyTextColor)
                .setQuantityTextColor(setQuantityTextColor);

              newPrimitives.push(primitive);
              usedPrimitiveIds.push(primitive?._line?._id);
            } catch (e) {
              //console.log('silent catch3', e)
            }
          }
        }
      }

      if (localStorage.getItem('showSR') === 'false' || ['sgsfutures', 'stepgridscalp', 'sgsnextgen', 'channelmaestro'].includes(buy_method_low) ? false : true) {
        newLineData.Res1 = {
          price: pairData?.R1 || 0,
          amount: 0,
          label: "Resistance 1",
          length: 100,
          style: 2,
          color: 'rgba(191,191,191,1)',
        }
        newLineData.Res2 = {
          price: pairData?.R2 || 0,
          amount: 0,
          label: "Resistance 2",
          length: 100,
          style: 2,
          color: 'rgba(191,191,191,1)',
        }
        newLineData.Sup1 = {
          price: pairData?.S1 || 0,
          amount: 0,
          label: "Support 1",
          length: 100,
          style: 2,
          color: 'rgba(191,191,191,1)',
        }
        newLineData.Sup2 = {
          price: pairData?.S2 || 0,
          amount: 0,
          label: "Support 2",
          length: 100,
          style: 2,
          color: 'rgba(191,191,191,1)',
        }
      }

      // place and update legacy lines
      Object.keys(newLineData).forEach(item => {
        const element = newLineData[item]

        const existingPrimitive = _.find(window.primitives, item => item?._data?.bodyText === element.label);
        if (!_.isNil(existingPrimitive)) {
          const prevPrice = existingPrimitive.getPrice()
          const prevQuantity = existingPrimitive.getQuantity()
          try {
            if (parseFloat(element?.price) === parseFloat(prevPrice) && ((parseFloat(element?.amount) === parseFloat(prevQuantity)) || (isNaN(parseFloat(prevQuantity) && isNaN(parseFloat(element?.amount)))))) {
              // nothing to change, only store privitive id for future reference
              usedPrimitiveIds.push(existingPrimitive._line?._id);
            }
            else {
              existingPrimitive.setPrice(element.price);
              existingPrimitive.setQuantity(element.amount);
              usedPrimitiveIds.push(existingPrimitive._line?._id);
            }
          } catch (error) {
            //console.log('silent catch4', error)
          }
        } else {
          try {
            if (_.isFunction(element?.onMove)) {
              let primitive = window.tvWidget
                .activeChart()
                .createOrderLine()
                .setText(!_.isNil(element.label) ? element.label : '')
                .setPrice(!_.isNil(element.price) ? element.price : 0)
                .setQuantity(!_.isNil(element.amount) ? element.amount : '')
                .setLineStyle(!_.isNil(element.style) ? element.style : 2)
                .setLineLength(!_.isNil(element.length) ? element.length : 0)
                .setExtendLeft(true)
                .setBodyBackgroundColor('rgba(255, 255, 255, 1)')
                .setBodyBorderColor(element.color)
                .setQuantityBackgroundColor(element.color)
                .setLineColor(element.color)
                .onMove(!_.isNil(element.onMove) ? element.onMove : function () { })
                .setLineWidth(!_.isNil(element.width) ? element.width : 1);
              newPrimitives.push(primitive);
              usedPrimitiveIds.push(primitive?._line?._id);
            }
            else {
              let primitive = window.tvWidget
                .activeChart()
                .createPositionLine()
              if (!_.isNil(primitive)) {
                primitive
                  .setText(!_.isNil(element.label) ? element.label : '')
                  .setPrice(!_.isNil(element.price) ? element.price : 0)
                  .setQuantity(!_.isNil(element.amount) ? element.amount : '')
                  .setLineStyle(!_.isNil(element.style) ? element.style : 2)
                  .setLineLength(!_.isNil(element.length) ? element.length : 0)
                  .setExtendLeft(true)
                  .setBodyBackgroundColor('rgba(255, 255, 255, 1)')
                  .setBodyBorderColor(element.color)
                  .setQuantityBackgroundColor(element.color)
                  .setLineColor(element.color)
                  .setLineWidth(!_.isNil(element.width) ? element.width : 1);
              }
              newPrimitives.push(primitive);
              usedPrimitiveIds.push(primitive?._line?._id);
            }
          } catch (e) {
            //console.log('silent catch5', e)
          }
        }
      });

      // place and update lines
      customChartTargets.forEach(element => {
        if (!isNotLegacyStrat) {
          return
        }
        const reservedNames = [
          'Resistance 1',
          'Resistance 2',
          'Support 1',
          'Support 2',
          'Buy at',
          'Pingpong buy',
          'Sell at',
          'Sell target',
          'Pingpong sell',
          'Break even',
          'Unit cost',
          'Entry Price',
          'Break even (short)',
          'Entry Price (short)',
          'RT BUY at',
          'RT BUY UP at',
          'RT SELL at',
          'RT SELL at ',
          'RT SELL UP trailing stop',
          'Buy trailing stop (tssl)',
          'RT Buy trailing stop',
          'Sell trailing stop (tssl)',
          'Buy trailing stop (tm)',
          'Sell trailing stop (tm)',
          'DCA trailing stop',
          'ROE trailing stop',
          'ROE scalper stop',
          'Stop limit',
          'DCA buydown',
          'Grid DCA target',
          'Grid sell target',
          'CLOSE at (fg)',
          'Grid sell trailing',
          'Grid sell trailing (fg)',
          'Grid buy trailing',
          'Grid buy trailing (fg)',
          'New position trailing',
          'New position trailing (fg)',
          'Buy target (c)',
          'DCA target (c)',
          'Sell target (c)',
          'Close target (c)',
          'Trailing target (c)',
          'Stop target (c)',
        ];
        // ignore lines that use a reserved name
        if (reservedNames.includes(element.text)) {
          return;
        }

        let existingPrimitive = _.find(window.primitives, item => item?._data?.bodyText === element.text);
        if (!_.isNil(existingPrimitive)) {
          const prevPrice = existingPrimitive.getPrice()
          const prevQuantity = existingPrimitive.getQuantity()
          try {
            if (parseFloat(element?.price) === parseFloat(prevPrice) && ((parseFloat(element?.quantity) === parseFloat(prevQuantity)) || (isNaN(parseFloat(prevQuantity) && isNaN(parseFloat(element?.quantity)))))) {
              // nothing to change, only store privitive id for future reference
              usedPrimitiveIds.push(existingPrimitive._line?._id);
            }
            else {
              existingPrimitive.setText(!_.isNil(element.text) ? element.text : "");
              existingPrimitive.setPrice(!_.isNil(element.price) ? element.price : 0);
              existingPrimitive.setQuantity(!_.isNil(element.quantity) ? element.quantity : 0);
              existingPrimitive.setLineStyle(!_.isNil(element.lineStyle) ? element.lineStyle : 2);
              existingPrimitive.setLineLength(!_.isNil(element.lineLength) ? element.lineLength : 0);
              existingPrimitive.setExtendLeft(!_.isNil(element.extendLeft) ? element.extendLeft : true);
              existingPrimitive.setBodyBackgroundColor(!_.isNil(element.bodyBackgroundColor) ? element.bodyBackgroundColor : '#000');
              existingPrimitive.setBodyTextColor(!_.isNil(element.bodyTextColor) ? element.bodyTextColor : '#000');
              existingPrimitive.setBodyBorderColor(!_.isNil(element.bodyBorderColor) ? element.bodyBorderColor : '#000');
              existingPrimitive.setQuantityBackgroundColor(!_.isNil(element.quantityBackgroundColor) ? element.quantityBackgroundColor : '#000');
              existingPrimitive.setQuantityTextColor(!_.isNil(element.quantityTextColor) ? element.quantityTextColor : '#000');
              existingPrimitive.setQuantityBorderColor(!_.isNil(element.quantityBorderColor) ? element.quantityBorderColor : '#000');
              existingPrimitive.setLineColor(!_.isNil(element.lineColor) ? element.lineColor : '#fff');
              existingPrimitive.setLineWidth(!_.isNil(element.lineWidth) ? element.lineWidth : 1);
              usedPrimitiveIds.push(existingPrimitive._line?._id);
            }
          } catch (error) {
            //console.log('silent catch6', error)
          }
        } else {
          try {
            let primitive = window.tvWidget
              .activeChart()
              .createPositionLine()

            if (!_.isNil(primitive)) {
              primitive
                .setText(!_.isNil(element.text) ? element.text : "")
                .setPrice(!_.isNil(element.price) ? element.price : 0)
                .setQuantity(!_.isNil(element.quantity) ? element.quantity : 0)
                .setLineStyle(!_.isNil(element.lineStyle) ? element.lineStyle : 2)
                .setLineLength(!_.isNil(element.lineLength) ? element.lineLength : 0)
                .setExtendLeft(!_.isNil(element.extendLeft) ? element.extendLeft : true)
                .setBodyBackgroundColor(!_.isNil(element.bodyBackgroundColor) ? element.bodyBackgroundColor : '#000')
                .setBodyTextColor(!_.isNil(element.bodyTextColor) ? element.bodyTextColor : '#000')
                .setBodyBorderColor(!_.isNil(element.bodyBorderColor) ? element.bodyBorderColor : '#000')
                .setQuantityBackgroundColor(!_.isNil(element.quantityBackgroundColor) ? element.quantityBackgroundColor : '#000')
                .setQuantityTextColor(!_.isNil(element.quantityTextColor) ? element.quantityTextColor : '#fff')
                .setQuantityBorderColor(!_.isNil(element.quantityBorderColor) ? element.quantityBorderColor : '#fff')
                .setLineColor(!_.isNil(element.lineColor) ? element.lineColor : '#fff')
                .setLineWidth(!_.isNil(element.lineWidth) ? element.lineWidth : 1);
            }

            newPrimitives.push(primitive);
            usedPrimitiveIds.push(primitive?._line?._id);
          } catch (e) {
            //console.log('silent catch7', e)
            if (!_.isNil(window[exchange])) {
              if (!_.isNil(window[exchange][pair])) {
                window[exchange][pair] = {};
              }
            }
          }
        }
      });

      // remove old shapes
      if (window.shapes) {
        if (!_.isNil(window.tvWidget)) {
          window.tvWidget.onChartReady(() => {
            window.shapes.forEach(element => {
              if (!_.isNil(element)) {
                try {
                  const activeChart = window.tvWidget.activeChart();
                  if (activeChart) {
                    activeChart.removeEntity(element);
                  } else {
                    console.error('activeChart is not available.');
                  }
                } catch (error) {
                  console.error('An error occurred while removing the shape:', error);
                }
              }
            });
          });
        } else {
          console.error('tvWidget is not defined.');
        }
      }


      // place shapes
      customChartShapes.forEach((element, index) => {
        // make element not part of chart layout
        element.options.disableSave = true

        if (isNotLegacyStrat) {
          if (!_.isNil(window.tvWidget)) {
            window.tvWidget.onChartReady(() => {
              try {
                const activeChart = window.tvWidget.activeChart();
                if (activeChart) {
                  // Modify element.options to set zOrder if setInBackground is true
                  if (element.options.setInBackground) {
                    // Ensure element.options is an object
                    if (typeof element.options === 'object' && element.options !== null) {
                      element.options.zOrder = 'bottom';
                    } else {
                      console.error('element.options is not a valid object:', element.options);
                    }
                  }

                  // Create multipoint shape with the modified options
                  const shapeId = activeChart.createMultipointShape(element.points, element.options);

                  if (shapeId) {
                    newShapeIds.push(shapeId);
                  } else {
                    console.error('Failed to create shape with given points and options:', element);
                  }
                } else {
                  console.error('activeChart is not available.');
                }
              } catch (error) {
                console.error('An error occurred while creating or manipulating shape:', error);
              }
            });
          } else {
            console.error('tvWidget is not defined.');
          }
        }



      });

      if (pair !== window.lastChartPair && exchange !== window.lastChartExchange) {
        delete window[exchange];
        if (!_.isNil(window.lastChartExchange)) {
          delete window[window.lastChartExchange];
        }
      }

      if (_.isNil(window[exchange])) {
        window[exchange] = {};
      }
      if (_.isNil(window[exchange][pair])) {
        window[exchange][pair] = {};
      }


      if (newPrimitives.length > 0) {
        // store lines ids (lines can be modified)
        if (window.primitives.length > 0) {
          window.primitives = [...newPrimitives, ...window.primitives];
          window[exchange][pair].primitives = window.primitives;
        } else {
          window.primitives = null
          window.primitives = newPrimitives;
          window[exchange][pair].primitives = newPrimitives;
        }
      }

      if (newShapeIds.length > 0) {
        // store shape ids (all shapes get removed before placing new ones)
        window.shapes = null
        window.shapes = newShapeIds;
      }


      // clean out unused primitives
      if (!_.isNil(window.primitives) && usedPrimitiveIds.length > 0) {
        let temp = [];
        // window[exchange][pair].primitives
        window.primitives.forEach(function (primitive, index) {
          if (typeof primitive === 'object' && !_.isNil(primitive)) {
            const id = primitive?._line?._id;
            if (usedPrimitiveIds.indexOf(id) < 0 && typeof id != 'undefined') {
              try {
                // remove primitive from chart
                primitive.remove();

                // possibly loop trough all primitives to identify and later remove other with same label text
              } catch (error) {
                //console.log('silent catch8', error)
              }
            } else {
              temp.push(primitive);
            }
          }
        });

        // save remaining primitives
        if (!_.isNil(window[exchange][pair].primitives)) {
          window[exchange][pair].primitives = temp;
          if (lineCount !== window[exchange][pair].primitives.length) {
            setLineCount(window[exchange][pair].primitives.length)
          }
        }
      }

      window.lastChartPair = pair;
      window.lastChartExchange = exchange;
    }
  }, [pairData, currentSymbol]);

  useEffect(() => {
    if (!currentSymbol) return;
    if (!pairData || !isHeaderCreated || !orders) {
      //console.log('doing early return00', orders)
      return
    };
    if (typeof window?.tvWidget?.activeChart !== 'function') {
      //console.log('doing early return0', orders)
      return
    };
    if (!canSafelyInteractWithChart()) {
      //console.log('doing early return1', orders)
      return
    };
    if (!window?.tvWidget?.activeChart()) {
      //console.log('doing early return2', orders)
      return
    };

    let allShapes = []

    try {
      allShapes = window.tvWidget
        .activeChart()
        .getAllShapes().map(item => item.id)
    } catch (error) {
      //console.log('silent catch9', error)
    }
    entityIds.forEach(entityId => {
      if (typeof entityId === 'object' && JSON.stringify(entityId) !== '{}' && allShapes.includes(entityId)) {
        if (typeof entityId.remove === 'function') {
          return entityId.remove();
        }
      }
      if (window.tvWidget && window.tvWidget.activeChart()) {
        entityId && allShapes.includes(entityId) && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart().removeEntity(entityId);
      }

    });

    // default 0 visible range, use when api returns null
    let obj = {
      from: 0,
      to: 0,
    };

    let from;
    let visibleRange;

    try {
      if (window.tvWidget && window.tvWidget.activeChart()) {
        visibleRange = window.tvWidget.activeChart().getVisibleRange();
        from = visibleRange.from;
      }

    } catch (err) {
      from = obj.from;
      //console.log('silent catch10', err)
    }

    const newEntityIds = [];
    for (const index in orders) {
      if (orders.hasOwnProperty(index)) {
        if (index > 2000) break;
        const order = orders[index];
        let time = +new Date(order.time / 1000);
        if (time < from) break;
        let price = order.rate;
        let text = 'BUY';
        let icon = 0xf0d8;
        let color = config.bot?.BUY_MARKER_COLOR || '#00ff00';
        if (order.type === 'sell') {
          icon = 0xf0d7;
          color = config.bot?.SELL_MARKER_COLOR || '#ffbf00';
        }
        if (
          exchange.includes('binanceFutures') || exchange.includes('dydx') || exchange.includes('dydx4') ||
          exchange.includes('futures_gunthy') ||
          exchange.includes('bitmex') ||
          exchange.includes('bitmex_testnet') ||
          exchange.includes('krakenFutures') ||
          exchange.includes('okex5') ||
          exchange.includes('okgunbot') ||
          exchange.includes('bitget')
        ) {
          if (order.type === 'sell') {
            text = 'SHORT';
          } else {
            text = 'LONG';
          }
        }
        if (window.tvWidget.activeChart()) {
          let primitive = window.tvWidget.activeChart().createMultipointShape([{ time, price }], {
            text: text,
            icon,
            overrides: { color, size: 20 },
            lock: true,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            showInObjectsTree: false,
            shape: 'icon',
            zOrder: 'top',
          });
          newEntityIds.push(primitive);
        }

      }
    }
    const fib0 = pairData.slLongs_tpShorts || 0;
    const fib1 = pairData.slShorts_tpLongs || 0;

    if (fib0 > 0) {
      try {
        let from = Date.now() / 1000 - parseFloat(pairData['whatstrat']['SMAPERIOD']) * 60;
        let to = Date.now() / 1000;
        if (window.tvWidget && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart()) {
          let primitive = window.tvWidget.activeChart().createMultipointShape(
            [
              { time: from, price: fib1 },
              { time: to, price: fib0 },
            ],
            {
              shape: 'fib_retracement',
              overrides: {
                extendLines: true,
                horzLabelsAlign: 'right',
                transparency: 90,
              },
            },
          );
          newEntityIds.push(primitive);
        }


      } catch (e) {
        //console.log('silent catch11', e) 
      }
    }
    setEntityIds(newEntityIds);
    const lastOrder = orders[0] || { time: -1 };
    const previousTime = lastOrders[key] || 0;
    const oneHourAgo = Date.now() - 3600000;
    if (previousTime < lastOrder.time && lastOrder.time > oneHourAgo) {
      let text =
        _.capitalize(lastOrder.type) + ' order filled' + '\nRate: ' + lastOrder.rate + '\nAmount: ' + lastOrder.amount;
      if (lastOrder.type === 'buy') {
        sendNotification(text, 'success', true, false, true);
      } else {
        sendNotification(text, 'error', true, false, true);
      }

      setLastOrders({ ...lastOrders, [key]: lastOrder.time });
    }

  }, [orders, currentSymbol, redrawOrders]);


  useEffect(() => {
    if (!pairData || !isHeaderCreated) return;
    /*
    if (window.tvWidget && typeof window.tvWidget?.activeChart === 'function' && window.tvWidget.activeChart()) {
      window.tvWidget.activeChart().refreshMarks()
    }
    */
  }, [lineCount, orders]);


  useEffect(() => {
    if (!currentSymbol) return;
    if (!pairStrat || !isHeaderCreated) return;
    if (Object.keys(pairStrat || {}).length < 2) return;
    if (!window.tvWidget) return;
    if (!window.tvWidget.activeChart) return;
    const { exchange } = currentSymbol;
    if (!exchange) return;
    const newEntityIds = [];
    let existingStudyIds = hasSetInitIndicators ? templateIndicators : []

    if (!hasSetInitIndicators) {
      // save indicators stored in the active template, to not duplicate these indicators with strategy related indicators
      window.tvWidget.activeChart().getAllStudies().forEach(({ name, id }) => {
        existingStudyIds.push({ name, id })
      });

      existingStudyIds.forEach(element => {
        const studyInputs = window.tvWidget.activeChart().getStudyById(element.id).getInputValues()
        element.inputs = studyInputs
      });
      setTemplateIndicators(existingStudyIds)
    }

    // clear programatically placed studies before placing new ones
    indicatorsToKeep.forEach(item => {
      async function removeIndicators() {
        let id = await item;
        window.tvWidget.activeChart().removeEntity(id);
      }
      removeIndicators();
    });

    // set volume by default
    try {
      const templateHasVolume = existingStudyIds.some(element => element.name === 'Volume');
      if (!templateHasVolume && config?.bot?.SHOW_VOLUME_INDICATOR !== false) {
        let entity = window.tvWidget.activeChart().createStudy("Volume", true, false);
        newEntityIds.push(entity);
      }
    } catch (e) {
      //console.log(e)
    }

    // methods using EMA
    if (
      pairStrat?.BUY_METHOD.toLowerCase() === 'bb' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'emotionless' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'emaspread' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'emaspread' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'gain' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'stepgain' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'tssl' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'gridbot' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'gridbotadvanced' ||
      pairStrat?.EMASPREAD === true
    ) {
      try {
        const templateHasEma1 = existingStudyIds.some((element) => {
          return element.name === 'Moving Average Exponential' && element.inputs.filter(item => item.id === 'length')[0].value == pairStrat?.EMA1
        });
        if (!templateHasEma1) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Moving Average Exponential', true, false, [pairStrat?.EMA1, 'close', 0]);
          newEntityIds.push(entity);
        }

        const templateHasEma2 = existingStudyIds.some((element) => {
          return element.name === 'Moving Average Exponential' && element.inputs.filter(item => item.id === 'length')[0].value == pairStrat?.EMA2
        });
        if (!templateHasEma2) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Moving Average Exponential', true, false, [pairStrat?.EMA2, 'close', 0]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch12', e) 
      }
    }

    if (
      pairStrat?.BUY_METHOD.toLowerCase() === 'adx' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'adx' ||
      pairStrat?.ADX_ENABLED === true
    ) {
      try {
        const templateHasDI = existingStudyIds.some(element => element.name === 'Directional Movement' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.ATR_PERIOD && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.ATR_PERIOD);
        if (!templateHasDI) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Directional Movement', false, false, [pairStrat?.DI_PERIOD, pairStrat?.DI_PERIOD]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch13', e) 
      }
    }

    if (pairStrat?.BUY_METHOD.toLowerCase() === 'atrts' || pairStrat?.SELL_METHOD.toLowerCase() === 'atrts') {
      try {
        const templateHasATR = existingStudyIds.some(element => element.name === 'Average True Range' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.ATR_PERIOD);
        if (!templateHasATR) {
          let entity = window.tvWidget.activeChart().createStudy('Average True Range', false, false, [pairStrat?.ATR_PERIOD]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch14', e) 
      }
    }

    if (
      pairStrat?.BUY_METHOD.toLowerCase() === 'bb' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'bb' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'bbta' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'bbta'
    ) {
      try {
        const templateHasBB = existingStudyIds.some(element => element.name === 'Bollinger Bands' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.SMAPERIOD && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.STDV);
        if (!templateHasBB) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Bollinger Bands', true, false, {
              in_0: pairStrat?.SMAPERIOD,
              in_2: pairStrat?.STDV
            },);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch15', e) 
      }
    }

    if (
      pairStrat?.BUY_METHOD.toLowerCase() === 'ichimoku' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'ichimoku' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'tenkan' ||
      pairStrat?.MM_TENKAN
    ) {
      try {
        const templateHasIchimoku = existingStudyIds.some(element => element.name === 'Ichimoku Cloud' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.TENKAN_PERIOD && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.KIJUN_PERIOD && element.inputs.filter(item => item.id === 'in_2')[0].value == pairStrat?.SENKOUSPAN_PERIOD && element.inputs.filter(item => item.id === 'in_3')[0].value == pairStrat?.DISPLACEMENT);
        if (!templateHasIchimoku) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Ichimoku Cloud', true, false, [
              pairStrat?.TENKAN_PERIOD,
              pairStrat?.KIJUN_PERIOD,
              pairStrat?.SENKOUSPAN_PERIOD,
              pairStrat?.DISPLACEMENT,
            ]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch16', e) 
      }
    }

    if (
      pairStrat?.BUY_METHOD.toLowerCase() === 'macd' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'macd' ||
      pairStrat?.BUY_METHOD.toLowerCase() === 'macdh' ||
      pairStrat?.SELL_METHOD.toLowerCase() === 'macdh'
    ) {
      try {
        const templateHasIchimoku = existingStudyIds.some(element => element.name === 'MACD' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.MACD_SHORT && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.MACD_LONG && element.inputs.filter(item => item.id === 'in_2')[0].value == 'close' && element.inputs.filter(item => item.id === 'in_3')[0].value == pairStrat?.MACD_SIGNAL);
        if (!templateHasIchimoku) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('MACD', false, false, [
              pairStrat?.MACD_SHORT,
              pairStrat?.MACD_LONG,
              'close',
              pairStrat?.MACD_SIGNAL,
            ]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch17', e) 
      }
    }

    if (pairStrat?.BUY_METHOD.toLowerCase() === 'smacross' || pairStrat?.SELL_METHOD.toLowerCase() === 'smacross') {
      try {
        const templateHasSlowSMA = existingStudyIds.some(element => element.name === 'Moving Average' && element.inputs.filter(item => item.id === 'length')[0].value == pairStrat?.SLOW_SMA);
        if (!templateHasSlowSMA) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Moving Average', true, false, [pairStrat?.SLOW_SMA, 'close', 0]);
          newEntityIds.push(entity);
        }

        const templateHasFastSMA = existingStudyIds.some(element => element.name === 'Moving Average' && element.inputs.filter(item => item.id === 'length')[0].value == pairStrat?.FAST_SMA);
        if (!templateHasFastSMA) {
          let entity = window.tvWidget.activeChart().createStudy('Moving Average', true, false, [pairStrat?.FAST_SMA, 'close', 0]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch18', e) 
      }
    }

    if (pairStrat?.MFI_ENABLED === true) {
      try {
        const templateHasMFI = existingStudyIds.some(element => element.name === 'Money Flow Index' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.MFI_LENGTH);
        if (!templateHasMFI) {
          let entity = window.tvWidget.activeChart().createStudy('Money Flow Index', false, false, [pairStrat?.MFI_LENGTH]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch19', e)
      }
    }

    if (pairStrat?.RSI_BUY_ENABLED === true || pairStrat?.RSI_SELL_ENABLED === true) {
      try {
        const templateHasRSI = existingStudyIds.some(element => element.name === 'Relative Strength Index' && element.inputs.filter(item => item.id === 'length')[0].value == pairStrat?.RSI_LENGTH);
        if (!templateHasRSI) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Relative Strength Index', false, false, [pairStrat?.RSI_LENGTH]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch19', e) 
      }
    }

    // StochRSI does not match GB StochRSI settings options (hardcoded values for some inputs without equivalent in gunbot)
    if (pairStrat?.STOCHRSI_ENABLED === true) {
      try {
        const templateHasStochRsi = existingStudyIds.some(element => element.name === 'Stochastic RSI' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.STOCHRSI_LENGTH && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.STOCHRSI_LENGTH);
        if (!templateHasStochRsi) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Stochastic RSI', false, false, [pairStrat?.STOCHRSI_LENGTH, pairStrat?.STOCHRSI_LENGTH, 3, 3]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch20', e)
      }
    }

    if (pairStrat?.STOCH_ENABLED === true) {
      try {
        const templateHasStoch = existingStudyIds.some(element => element.name === 'Stochastic' && element.inputs.filter(item => item.id === 'in_0')[0].value == pairStrat?.STOCH_K && element.inputs.filter(item => item.id === 'in_1')[0].value == pairStrat?.SLOW_STOCH_K && element.inputs.filter(item => item.id === 'in_2')[0].value == pairStrat?.STOCH_D);
        if (!templateHasStoch) {
          let entity = window.tvWidget
            .activeChart()
            .createStudy('Stochastic', false, false, [pairStrat?.STOCH_K, pairStrat?.SLOW_STOCH_K, pairStrat?.STOCH_D]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch21', e) 
      }
    }

    if (pairStrat?.oneSCALPER === true) {
      try {
        const templateHasFastSMA = existingStudyIds.some(element => element.name === 'Moving Average' && element.inputs.filter(item => item.id === 'length')[0].value == '99');
        if (!templateHasFastSMA) {
          let entity = window.tvWidget.activeChart().createStudy('Moving Average', true, false, ['99', 'close', 0]);
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch22', e) 
      }
    }
    if (pairStrat?.EWO === true) {
      try {
        const templateHasEWO = existingStudyIds.some(element => element.name === 'Elliot Waves Oscillator');
        if (!templateHasEWO) {
          let entity = window.tvWidget.activeChart().createStudy('Elliot Waves Oscillator');
          newEntityIds.push(entity);
        }

        const templateHasCloud = existingStudyIds.some(element => element.name === 'Ichimoku Cloud');
        if (!templateHasCloud) {
          let entity = window.tvWidget.activeChart().createStudy('Ichimoku Cloud');
          newEntityIds.push(entity);
        }
      } catch (e) {
        //console.log('silent catch23', e) 
      }
    }

    setIndicatorsToKeep(newEntityIds);
    setEntityIds(newEntityIds);

    // Debug logs to get details of inserted studies
    //  async function gimmeEntity (){
    // 	const hasEntity = await entityIds[1]
    // 	console.log(hasEntity)
    // 	console.log(tvWidget.activeChart().getStudyById(hasEntity))
    // 	}

    // 	gimmeEntity()

    window.tvWidget.applyStudiesOverrides({
      'stochastic rsi.UpperLimit.value':
        pairStrat?.STOCHRSI_SELL_LEVEL == -1001
          ? pairStrat?.STOCHRSI_BUY_LEVEL * 100
          : pairStrat?.STOCHRSI_SELL_LEVEL * 100,
      'stochastic rsi.LowerLimit.value':
        pairStrat?.STOCHRSI_BUY_LEVEL == -1001
          ? pairStrat?.STOCHRSI_SELL_LEVEL * 100
          : pairStrat?.STOCHRSI_BUY_LEVEL * 100,
      'stochastic.UpperLimit.value':
        pairStrat?.STOCH_SELL_LEVEL == -1001 ? pairStrat?.STOCH_BUY_LEVEL : pairStrat?.STOCH_SELL_LEVEL,
      'stochastic.LowerLimit.value':
        pairStrat?.STOCH_BUY_LEVEL == -1001 ? pairStrat?.STOCH_SELL_LEVEL : pairStrat?.STOCH_BUY_LEVEL,
      'relative strength index.UpperLimit.value':
        pairStrat?.RSI_SELL_LEVEL == -1001 ? pairStrat?.RSI_BUY_LEVEL : pairStrat?.RSI_SELL_LEVEL,
      'relative strength index.LowerLimit.value':
        pairStrat?.RSI_BUY_LEVEL == -1001 ? pairStrat?.RSI_SELL_LEVEL : pairStrat?.RSI_BUY_LEVEL,
      'money flow index.UpperLimit.value':
        pairStrat?.MFI_SELL_LEVEL == -1001 ? pairStrat?.MFI_BUY_LEVEL : pairStrat?.MFI_SELL_LEVEL,
      'money flow index.LowerLimit.value':
        pairStrat?.MFI_BUY_LEVEL == -1001 ? pairStrat?.MFI_SELL_LEVEL : pairStrat?.MFI_BUY_LEVEL,
      'volume.volume.color.0': '#7B232F',
      'volume.volume.color.1': '#076641',
    });

    setHasSetInitIndicators(true)
  }, [pairStrat]);


  const classes = useStyle();
  const locationIsChart = props.history.location.pathname === '/chart';

  // handle reloading page after having lost socket connection or when starting/stopping core. required to not have broken charts after reconnect
  if (active && (window.chartShouldRefresh || window.startStopPressed)) {
    //window.tvWidget = null
    window.chartShouldRefresh = false

    if (window.startStopPressed) {
      // this prevents a double reload after stop/start has been pressed
      window.startStopPressed = false
    }
    else {
      setTimeout(() => {
        // reload later, to not reload while the actual process restart is happening and gui server cannot be reached
        const alreadyJustReloaded = !window.lastInitialConnect ? false : Date.now() - window.lastInitialConnect < 5000
        active && !alreadyJustReloaded && config.GUI.start === true && config.GUI?.allowreloads !== false && window.location.reload()
      }, 10);
    }

  }

  return (
    <div className={isMobile ? classes.rootMobile : classes.rootDesktop}>

      {
        isMobile ?(
          <>
            <Event event="updateCb" handler={updateCb} />
            {!active && locationIsChart && <CoreDisabled />}
            <Orders symbol={currentSymbol} />
        
            {/* Full-screen mobile wrapper */}
            <div
              style={{
                position: 'fixed',
                top: '64px', // Adjust to your mobile top navbar height
                left: 0,
                right: 0,
                bottom: '56px', // Adjust to your mobile bottom nav height
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              {/* Main Content */}
              <div style={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
                {/* Chart container with mobile loading overlay */}
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: navbarValue === 0 ? 'flex' : 'none',
                  }}
                >
                  {/* Render mobile loading overlay until header is created */}
                  { !isHeaderCreated && (
                    <div className={classes.mobileLoadingOverlay}>
                      <div className={classes.mobileLoadingIndicator}>
                        <div className={classes.mobileLoadingDots}>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <span className={classes.mobileLoadingText}>Loading chart data</span>
                      </div>
                    </div>
                  )}
        
                  {/* TradingView container */}
                  <div
                    id={`${props.containerId}_mobile`}
                    className={classes.TVChartContainer}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
        
                {/* Overlay Panels for other navbar items */}
              {navbarValue !== 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflowY: 'auto',
                    backgroundColor: '#222',
                    zIndex: 1000,
                  }}
                >
                  {navbarValue === 1 && (
                    <OverviewPairCard
                      alone={false}
                      sideBar={true}
                      exchange={exchange}
                      pair={currentSymbol?.pair}
                      showSettings={false}
                      showStats={true}
                      isMobile={true}
                    />
                  )}
        
                  {navbarValue === 2 && (
                    <>
                     <Grid container spacing={2}>
                      <Grid item xs style={{ textAlign: 'center' }}>
                        <p
                          style={{
                            color: '#e7e7e7',
                            fontSize: '22px',
                            fontWeight: 600,
                            paddingTop: '10px',
                          }}
                        >
                          {currentSymbol?.pair.replace('-', ' - ')}
                        </p>
                      </Grid>
                    </Grid>
                    <OverviewPairCard
                    alone={true}
                    sideBar={true}
                    exchange={exchange}
                    pair={currentSymbol?.pair}
                    showSettings={true}
                    showStats={false}
                    isMobile={true}
                  />
                    </>
                  )}
        
                  {navbarValue === 3 && (
                    <TradingTerminal
                      pair={currentSymbol?.pair}
                      exchange={exchange}
                      isCoinM={config?.exchanges?.[exchange]?.market === 'delivery' || config?.exchanges?.[exchange]?.market.includes('inverse') || ((config?.exchanges?.[exchange]?.market === 'swap' && !exchange.includes('bitget')) || (config?.exchanges?.[exchange]?.market === 'swap' && exchange.includes('bitget') && currentSymbol.pair.split('-')[0] !== 'USDT'))}
                      hasLong={!_.isNil(pairData) && pairData?.currentQty > 0}
                      hasShort={!_.isNil(pairData) && pairData?.currentQty < 0}
                      marketType={config?.exchanges?.[exchange]?.market}
                      currentQty={!_.isNil(pairData) && pairData?.currentQty}
                    />
                  )}
                </div>
              )}
              </div>
            </div>
        
            {/* Bottom Navigation */}
            {locationIsChart && (
              <BottomNavigation
                value={navbarValue}
                onChange={(event, newValue) => setNavbarValue(newValue)}
                className={classes.BottomNavigation}
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '56px',
                  zIndex: 1100,
                }}
              >
                <BottomNavigationAction label="Chart" icon={<ChartIcon />} />
                <BottomNavigationAction label="Stats" icon={<InfoIcon />} />
                <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
                <BottomNavigationAction label="Trade" icon={<TradingIcon />} />
              </BottomNavigation>
            )}
          </>
        )
          :
          <>
            <Event event="updateCb" handler={updateCb} />
            {isHeaderCreated && startExport && <AlertProcessor entities={conditions} tvWidget={window.tvWidget} pairStrat={pairStrat} />}
            {!active && locationIsChart && <CoreDisabled />}
            <Orders symbol={currentSymbol} />
            {licenseType !== 'one' && !embeddedView && (
              <div className={classes.overviewSideBarWrapper + ' left'}>
                <MarketSidebarNavigation />
                <div className={classes.overviewSideBar + ' ' + classes.leftSide + (!isOpenMarketsSideBar ? ' hidden' : '')}>
                  {isOpenMarketsSideBar && (
                    <MarketsTable
                      selectedSymbol={currentSymbol?.name}
                      setSelectedSymbol={setSelectedSymbol}
                      history={props.history}
                      symbols={symbols}
                    />
                  )}
                </div>
              </div>
            )}
            {!isHeaderCreated && (
              <div className={classes.loadingMessage}>
                <div className={classes.header}>
                  <div className={classes.headerControls}>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div className={classes.sidebar}>
                  <div className={classes.sidebarIcon} />
                  <div className={classes.sidebarIcon} />
                  <div className={classes.sidebarIcon} />
                  <div className={classes.sidebarIcon} />
                </div>
                <div className={classes.chartArea}>
                  <div className={classes.chartBackground} />
                  <div className={classes.chartSkeleton}>
                    {generateCandlestickData(150).map((candle, i) => (
                      <div
                        key={i}
                        className="candle"
                        style={{
                          height: `${candle.height}px`,
                          left: candle.left,
                          bottom: candle.bottom,
                          '&::before': {
                            height: `${candle.wickTop}px`,
                            bottom: '100%',
                          },
                          '&::after': {
                            height: `${candle.wickBottom}px`,
                            top: '100%',
                          },
                        }}
                      />
                    ))}
                  </div>
                  {
                    config?.GUI?.start &&
                    <div className={classes.loadingIndicator}>
                      <div className={classes.dots}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <span className={classes.text}>Loading chart data</span>
                    </div>
                  }
                </div>
              </div>
            )}
            <div id={`${props.containerId}_desktop`} className={classes.TVChartContainer} />
            {!embeddedView && (
              <div className={classes.overviewSideBarWrapper}>
                <div
                  style={isOpenSideBar && window.innerWidth <= 960 ? { width: '350px' } : null}
                  className={classes.overviewSideBar + (!isOpenSideBar ? ' hidden' : '')}
                >
                  <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                    {isOpenSideBar && sideBarState === 'overview' && (
                      <>
                        <Box py={0} mt={1} mb={1} mx={3}>
                          <OverviewPairCard
                            alone={false}
                            sideBar={true}
                            exchange={exchange}
                            pair={currentSymbol?.pair}
                            showSettings={false}
                            showStats={true}
                          />
                        </Box>
                        <Box flex={'auto'} className={classes.overflow}>
                          {licenseType !== 'one' ? (
                            <Accordion style={{ borderTop: '1px solid #3c3c3c' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
                                  Pair settings
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <OverviewPairCard
                                  alone={true}
                                  sideBar={true}
                                  exchange={exchange}
                                  pair={currentSymbol?.pair}
                                  showSettings={true}
                                  showStats={false}
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : null}
                          <TradingTerminal
                            pair={currentSymbol?.pair}
                            exchange={exchange}
                            isCoinM={config?.exchanges?.[exchange]?.market === 'delivery' || config?.exchanges?.[exchange]?.market.includes('inverse') || ((config?.exchanges?.[exchange]?.market === 'swap' && !exchange.includes('bitget')) || (config?.exchanges?.[exchange]?.market === 'swap' && exchange.includes('bitget') && currentSymbol.pair.split('-')[0] !== 'USDT'))}
                            hasLong={!_.isNil(pairData) && pairData?.currentQty > 0}
                            hasShort={!_.isNil(pairData) && pairData?.currentQty < 0}
                            marketType={config?.exchanges?.[exchange]?.market}
                            currentQty={!_.isNil(pairData) && pairData?.currentQty}
                          />
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
                                Notes
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Notes
                                currentPair={currentSymbol.pair}
                                currentExchange={currentSymbol.exchange}
                                useOverlay={false}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </>
                    )}
                    {isOpenSideBar && sideBarState === 'alarmView' && (
                      <>
                        <Box fontSize={'20px'} fontWeight={600} color={'white'} my={2} mb={-1}>
                          Alerts (beta)
                        </Box>
                        <Box flex={'auto'} className={classes.overflow}>
                          <Alerts entities={conditions} pairKey={key} />
                        </Box>
                      </>
                    )}
                  </Box>
                </div>
                <RightSidebarNavigation licenseType={licenseType} />
              </div>
            )}
          </>
      }
    </div>
  );
}

const dummyData = {
  pairs: {
    binance: {
      'BTC-DUMMY': {
        strategy: 'dummy',
        enabled: false,
        override: {},
      },
    },
  },
  strategies: {
    dummy: {
      PERIOD: 1440,
    },
  },
};

TVChartContainer.defaultProps = {
  interval: '5m',
  containerId: 'tv_chart_container',
  datafeedUrl: 'https://demo_feed.tradingview.com',
  libraryPath: 'charting_library/',
  chartsStorageUrl: window.location.origin + '/api/v1',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: false,
  autosize: true,
  studiesOverrides: {
    // 'volume.volume.color.0': '#999999',
    // 'volume.volume.color.1': '#4c4c4c',
  },
  initialSettings: {
    symbolWatermark: true,
    toolbar_bg: '#222222',
    save_chart_properties_to_local_storage: true,
    study_market_minimized: false,
    supports_timescale_marks: true,
  },
  overrides: {
    volumePaneSize: 'medium',
    editorFontsList: ['Verdana', 'Courier New', 'Times New Roman', 'Arial'],
    "paneProperties.background": "#222222",
    "paneProperties.backgroundType": "solid",
    'mainSeriesProperties.showCountdown': true,
    "clear_price_scale_on_error_or_empty_bars": false,
  },
};

TVChartContainer.propTypes = {
  history: PropTypes.object.isRequired,
};


const generateCandlestickData = (numCandles, initialPrice = 50) => {
  let price = initialPrice; // Starting price point
  const candles = [];
  const volatility = 2;
  const previousCloseByTimeframe = {};

  for (let i = 0; i < numCandles; i++) {
    // Generate random direction for each candle
    const randomDirection = Math.random() > 0.5 ? 1 : -1;

    // Open price based on the previous close (or initial price for the first candle)
    const open = (previousCloseByTimeframe['current'] || price) + (Math.random() * volatility * randomDirection);

    // Close price moves in a direction relative to the open price
    const close = open + (Math.random() * volatility * randomDirection);

    // High and low prices around open and close
    const high = Math.max(open, close) + (Math.random() * volatility);
    const low = Math.min(open, close) - (Math.random() * volatility);

    // Set the price to close price for the next iteration
    previousCloseByTimeframe['current'] = close;

    // Calculate candle dimensions for display
    const candleHeight = Math.abs(high - low) * 15;
    const wickHeight = candleHeight * 0.3;

    candles.push({
      height: candleHeight,
      left: `${i * 16 + 40}px`,
      bottom: `${Math.min(open, close)}%`, // Positioning based on minimum of open/close
      wickTop: wickHeight,
      wickBottom: wickHeight
    });
  }

  return candles;
};
